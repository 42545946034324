import { CheckBox, Logout, Radio } from "@mui/icons-material";
import { Box, Button,  CircularProgress,  FormControlLabel,  IconButton,  LinearProgress,  ListItem,  Menu,  Stack, TextField, Tooltip, Typography } from "@mui/material";
import React from "react";
import { MenuItem, Select } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import FormControl from '@mui/material/FormControl';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import AddIcon from '@mui/icons-material/Add';
import 'primeicons/primeicons.css';
import { InputText } from "primereact/inputtext";
import { useEffect, useMemo, useRef, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import ClearIcon from '@mui/icons-material/Clear';
import { Row } from "primereact/row";
import "../../styles/receipt.css"
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import { Dialog } from "primereact/dialog";
import PrintIcon from '@mui/icons-material/Print';
import arial from '../../assets/fonts/Arialn.ttf'
import courier from '../../assets/fonts/cour.ttf'
import arialBold from '../../assets/fonts/Arial MT Black.ttf'
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import CustomAxios from "../../utils/CustomAxios";
import { useMediaQuery } from "react-responsive";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DoneIcon from '@mui/icons-material/Done';
import { useDispatch, useSelector } from "react-redux";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { AutoComplete } from "primereact/autocomplete";
import EditNoteIcon from '@mui/icons-material/EditNote';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { updateBulkListPayload } from "../../redux/executiveReducer";
import { RadioButton } from "primereact/radiobutton";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { useTheme } from '@mui/material/styles';
// import { Skeleton } from 'primereact/skeleton';
import MuiInput from "../../elements/Mui/mui_input";
import MuiAutocomplete from "../../elements/Mui/mui_Autocomplete";
import dayjs from "dayjs";
import MuiDatePicker from '../../elements/Mui/mui_date_picker'
import MuiSearchBar from '../../elements/Mui/mui_searchbar'
import MuiButton from "../../elements/Mui/mui_button";
import MuiInputAmount from "../../elements/Mui/mui_input_amount";

export function Receipts() {

    const theme = useTheme();

    const navigate = useNavigate()
    const [banks, setBanks] = useState([])
    const [Coopbanks, setCoopBanks] = useState([])
    const [estateArray, setestateArray] = useState([])
    const [bank, setBank] = useState("")
    const [date, setDate] = useState(null)
    const [newDate, setNewDate] = useState(null)
    const [estate, setEstate] = useState("")
    const [coopbank, setCoopbank] = useState("")
    const [bulkAmount, setBulkAmount] = useState("")
    const [narration, setNarration] = useState("")
    const [currentState, setCurrentState] = useState("")
    const [chequeNo, setChequeNo] = useState("")
    let zero= 0
    let zerbalance = zero.toFixed(2)
    const [checkbox, setCheckbox] = useState(false)
    const [manager, setManager] = useState('')
    const [specialNote, setSpecialNote] = useState("")
    const [memberid, setMemberId] = useState("")
    const [OldNric, setOldNric] = useState("")
    const [newNric, setNewNric] = useState("")
    const [memberName, setMemberName] = useState("")
    const [amount, setAmount] = useState("")
    const [transtype, setTransType] = useState("")
    const [currentMember, setCurrentMember] = useState([])
    const formRef = useRef(null);
    const formRefdeposit = useRef(null);
    const [payloadstatus, setpayloadStatus] = useState(false)
    const [transdetails, setTransdetails] = useState([])
    const [response, setResponse] = useState([])
    const [Bl, setBL] = useState(zero)
    const [Total, setTotal] = useState(zero)
    const [filteredMem, setFilteredMem] = useState([])
    const [memberShareCheck, setMemberSharecheck] = useState([])
    const [isLoading,setLoading] = useState(false)
    const [updatedTable,setUpdatedTable] = useState("")
    const [borderColor, setBorderColor] = useState('');
    const [errors,setErrors] = useState({share:"",member:"",depositDate:""})
    const [bulkList,setBulkList]=useState([])
    const [modalOpen,setModalOpen]=useState(false)
    const [searchEstate,setSearchEstate]=useState()
    const [checkEntry, setCheckEntry] = useState(false)
    const [payload,setPayload]=useState()
    let estateList = estateArray.map(data => data.estatename)
    let coopBankList = Coopbanks.filter((data) => data.coopbankname !== null).map((filteredData) => filteredData.coopbankname)
    let bankList = banks.filter((data) => data.bankname !== null).map((filteredData) => filteredData.bankname)
    const [bulkNo,setBulkNo]=useState("")
    const [bulkTrans,setBulktrans]=useState([])
    const [finalMembers,setfinalMembers] = useState([])
    const [pdflist,setPdfList] = useState([])
    const [receiptNo,setReceiptNo]=useState()
    const [cheque,setCheque]=useState("")
    const [action,setAction]=useState()
    const [loanEnable,setLoanEnable]=useState(true)
    const [finalList,setFinalList]=useState([])
    const [receiptModal,setReceiptModal]=useState(false)
    const [loanList,setLoanList]=useState([])
    const [lastTransList,setlastTransList]=useState([])
    const [finalmembersLastTrans,setfinalMembersLastTrans]=useState([])
    const [finalmembersSearched,setfinalMembersSearched]=useState([])
    const [loanid,setLoanid]=useState("")
    const [newReceipt,setNewReceipt]=useState(false) 
    const [anchorEl, setAnchorEl] = useState(null);
    const [printModal, setPrintModal] = useState(false);
    const [bulkHeading, setBulkHeading] = useState("");
    const [printModalButton, setprintModalButton] = useState("");
    const [searchBulkNo, setSearchBulk] = useState("");
    const [searchGlobalBulkNo, setSearchGlobalBulk] = useState("");
    const [repeatedTransModal,setRepeatedTransModal] = useState(false)
    const [success,setSucces] = useState(false)
    const [lasttrans,setLastTrans] = useState(false)
    const [pageNo,setPageNo] = useState(1)
    const [estatePageNo,setEstatePageNo] = useState(1)
    const memberList = useSelector(state => state.executive.memberLists)
    const bulklist = useSelector(state => state.executive.bulkList)
    const [items, setItems] = useState(null);
    const [estateLists, setestateLists] = useState(estateList);
    const [editdetails,setEditDetails]=useState(false)
    const [deleteModal,setDeletemodal]=useState(false)
    const [deleteStatus,setDeletedStatus]=useState(false)
    const [apiError,setApiError]=useState(false)
    const [rowDataToDelete, setRowDataToDelete] = useState(null);
    const [rowIndexToDelete, setRowIndexToDelete] = useState(null);
    // console.log(items)
    const today = new Date();
    const inputRef = useRef(null);
    const inputRefMemId = useRef(null);
    const inputRefadd = useRef(null);
    // const [shouldFocus, setShouldFocus] = useState(false);
    // const [shouldFocusmember, setShouldFocusmember] = useState(false);
    const [shouldFocusAddList, setShouldFocusAddList] = useState(false);
    const isMobile = useMediaQuery({ maxWidth: 600 })
    // Add 7 days to today's date
    // const sevenDaysAfter = new Date(today);
    // sevenDaysAfter.setDate(today.getDate() + 7);
    const containerRef = useRef(null);
    const [isCLLoading,setIsClLoading] = useState(false)
    const [receipt,setReceipt] = useState("")
    const dispatch = useDispatch()
    const [isFutureDateError, setIsFutureDateError] = useState(false)
    const [isSubmitted, setIsSubmitted] = useState(false)
    const inputRefMemName = useRef(null);
    const inputRefNewNric = useRef(null);
    const [isFetchedNewMember, setIsFetchedNewMember] = useState(false)
    const inputRefBulkAmt = useRef(null);

useEffect(()=>{
    const gettingStates = async () => {
        const finalMembers = await CustomAxios.get('oasis/form_details/').then((data) => {
            setBanks(data.data.bankName)
            setestateArray(data.data.estateList)
            setCoopBanks(data.data.coopBankname)
        })
    }
    gettingStates()
   
},[])
useEffect(()=>{
 if(bulklist.length > 0) {
setBulkList(bulklist)
    }
},[bulklist])
//console.log(typeof(bulkNo))
    const handleLogout = () => {
        sessionStorage.clear();
        navigate('/login');
    };
    let dateFormate = ""
    useEffect(() => {
        // //console.log("here", estateArray.length)
        if (estate&&estateArray.length > 0) {
            // //console.log("inhere")
            ////console.log(estateArray)
            const filtered = estateArray.filter((data) => estate == data.estatename || estate==data.estateid)
            ////console.log(filtered)
            setCurrentState(filtered[0].state+"-"+filtered[0].add4)
            setEstate(filtered[0].estatename)
            // console.log(filtered[0].state)
        }
        // //console.log(dateFormate)
        if(date ){
            if(typeof(date)!=='string'){
            setNewDate(date?.format("DD-MM-YYYY"))
            // dateFormate = date.map(item => {
            //     // //console.log(item)/

            //     const originalDate = new Date(item);
            //     ////console.log(originalDate)
            //     const year = originalDate.getFullYear();
            //     const month = String(originalDate.getMonth() + 1).padStart(2, '0');
            //     const day = String(originalDate.getDate()).padStart(2, '0');
            //     const hours = String(originalDate.getHours()).padStart(2, '0');
            //     const minutes = String(originalDate.getMinutes()).padStart(2, '0');
            //     const formattedDate = `${day}-${month}-${year}`;
            //     const seconds = String(originalDate.getSeconds()).padStart(2, '0');
            //     setNewDate(formattedDate)
            // })
        }
         else{
                const formate = date.split("T")[0]
                const dateasDate = new Date(formate)
                const day = dateasDate.getDate();
                const month = dateasDate.getMonth() + 1; 
                const year = dateasDate.getFullYear();
                const formattedDate = `${day < 10 ? '0' : ''}${day}-${month < 10 ? '0' : ''}${month}-${year}`;
                setNewDate(formattedDate)
            }}
    }, [estate,date])

    const handleFormattedAmount = amount => parseFloat(amount?.toString().replace(/[^0-9.]/g, ''))
    
    useEffect(()=>{
        setCheque(bank+" "+chequeNo)
       setPayload ((previous)=>{
        return {...previous,
            bankname: bank,
            coopbank:coopbank,
            date: newDate,
            estate: estate,
            state: currentState,
            transamount: parseInt(handleFormattedAmount(bulkAmount)),
            narration: narration,
            currentState: currentState,
            chequeno: chequeNo,
            estatemanagername: manager,}

       } 
        
    )
        // setPayloadupdate(payload)
    },[bank,coopbank,newDate,estate,currentState,bulkAmount,narration,currentState,chequeNo,manager])
    const handlesubmit = (e) => {
        e.preventDefault()
       //console.log(bank+" "+chequeNo)
        // setPayloadupdate(payload)
        
        setCheque(bank+" "+chequeNo)
        setpayloadStatus(true)
    }
    const decimalshare=(rowData)=>{
        return parseFloat(rowData.sharebalance).toFixed(2)
   }
   const decimalsub=(rowData)=>{
       return parseInt(rowData.subscriptionbalance).toFixed(2)
   }
   const decimalloan=(rowData)=>{
    // console.log(rowData)
    return parseInt(rowData.loanbalance).toFixed(2)
   }
   const TransType=(rowData)=>{
    // console.log(rowData)
    if(rowData.transtypeid==1){
        return "Share"

    }
    else if(rowData.transtypeid==2){
        return "Subscription"
    }
    else if(rowData.transtypeid==5){
        return "Loan Repayment"
    }
    else if(rowData.transtypeid==7){
        return "Entrance Fee"
    }
   
   }
   useEffect(()=>{
    let memberlist=[]
    if(bulkTrans.memberList?.length>0){
        // console.log("????????????????bulktrans",bulkTrans,bulkTrans.length)
        bulkTrans.memberList.map((element,ind)=>{
            ////console.log(element)
            // let loan=""
            if(element.transtypeid==5){
                // loan=await axios.post(api+"loanDetails/",{memberid:element.memberid})
                // //console.log(loan.data.success.loanid)
    
            }
            // //console.log(item)
            console.log(element)
            // await CustomAxios.get(`oasis/specificDeposit/?mid=${parseInt(element.memberid)}`).then((data)=>{
                // //console.log(data)
                // //console.log(item)
                // //console.log(data)
                // let item= data.data
                
            //     //console.log(item)67721
            const member =  bulkTrans.member.find(mem => mem.memberid === element.memberid);
            const item = {...member}
            console.log(item)
                if(item){
                     item.loanid=element.loanno
            
            
            item.transamount=parseInt(element.transamount)
            item.amount=parseInt(element.transamount)
            item.narration=element.narration
            item.bankname=element.bankname
            item.transtypeid=element.transtypeid
            item.transdetailid=element.transdetailid
            item.estatemanagername=element.estatemanagername
            if(action!=="dup"&&action!=="edit"){
                item.receiptid = element.transdetailid
                item.transmasterid=element.transmasterid  
            }
            if(action=="dup"){
                if(parseInt(item.sharebalance) >= 1200){
                    element.transtypeid= 2
                    item.transtypeid=element.transtypeid
                }else{
                    element.transtypeid= 1
                    item.transtypeid=element.transtypeid
                }
                item.updatedby = ""
                item.createdby = localStorage.getItem('username')
                console.log("dup bulk",item)
                let transExists = finalMembers.some(obj => obj.transdetailid == item.transdetailid);
                if(!transExists){
                setfinalMembers((previous)=>{return [...previous, item].sort((a,b)=>a.transdetailid-b.transdetailid )})
                }
            }
            if(action=="edit"){
                console.log("edit bulk")
                item.updatedby = localStorage.getItem('username')
                item.receiptid = element.transdetailid
                item.transmasterid=element.transmasterid
                let transExists = finalMembers.some(obj => obj.transdetailid == item.transdetailid);
                if(!transExists){
                setfinalMembers((previous)=>{return [...previous, item].sort((a,b)=>a.transdetailid-b.transdetailid )})
                }
            }
            // if(searchGlobalBulkNo!==""){                           // for the UI as per kumar proto
            //     console.log("searchGlobalBulkNo")
            //     let transExists = finalmembersSearched.some(obj => obj.receiptid == item.receiptid);
            //     if(!transExists){
            //     setfinalMembersSearched((previous)=>{return [...previous, item].sort((a,b)=>{
            //         if (a.transmasterid > b.transmasterid) return -1;
            //                 if (a.transmasterid < b.transmasterid) return 1;

            //                 // If masterids are equal, sort based on id in ascending order
            //                 if (a.receiptid > b.receiptid) return 1;
            //                 if (a.receiptid < b.receiptid) return -1;

            //                 return 0;
            //     } )})
            // }
            // }
            if(bulkHeading=="Download List"){
                console.log("Download List")
                let transExists = pdflist.some(obj => obj.transdetailid == item.transdetailid);
                if(!transExists){
                setPdfList((previous)=>{return [...previous, item].sort((a,b)=>a.transdetailid-b.transdetailid )})   
                }
            }
            if(lasttrans == true){
                console.log("inside lasttrans",bulkTrans.length-1,ind)
                let transExists = finalmembersLastTrans.some(obj => obj.receiptid == item.receiptid);
                if(!transExists){
                setfinalMembersLastTrans((previous)=>{return [...previous, item].sort((a,b)=>{
                    if (a.transmasterid > b.transmasterid) return -1;
                            if (a.transmasterid < b.transmasterid) return 1;

                            // If masterids are equal, sort based on id in ascending order
                            if (a.receiptid > b.receiptid) return 1;
                            if (a.receiptid < b.receiptid) return -1;

                            return 0;
                })})
            }
            }
            
            // finalMembers[count].transmasterid = data.transmasterid
            memberlist.push(item)
                }
               
            
           
            
    
        
    })
    
    // });
    // //console.log("????????????member list",memberlist)

    
if(bulkHeading!=="Download List"){
    setBulktrans([])
}
    }
    setLoading(false)
    setLastTrans(false)
   },[bulkTrans])

   useEffect(()=>{
    //console.log("insidetrantype change ")
let loan=""
if (transtype!=5){
    //console.log("insidetrantype change ")
    setLoanEnable(true)
}
else {
    loan_Numbers()
   //console.log(loan)

 

}
if(finalMembers.length>0&&finalMembers[0].receiptid!=''){
    //console.log(finalMembers)
    finalMembers.sort((a,b)=>a.receiptid-b.receiptid)
    
}
   },[transtype,finalMembers])
   const loan_Numbers=async()=>{
   let loan=await CustomAxios.post("oasis/loanDetails/",{memberid:currentMember[0].memberid})
   setLoanList(loan.data.success)
   setLoanEnable(false)


   }
    const SelectedBulk=async(rowDate,action)=>{
        setLoading(true)
        console.log(lastTransList)
        let bulkList
        let response
        let formattedDate
        let sorted = []
        let dateObject
        if(action=='edit'||action=='dup'){
            setEditDetails(true)
            setAction(action)
            setNewReceipt(true)
            errors.member=""
            errors.share=""
            errors.depositDate=""
        }
        setfinalMembers([])
        //console.log(lastTransList)
        if(lastTransList.length>0){
            let newRes = lastTransList.map(async(list,ind)=>{
                const load={transmaster:list.transmasterid}
                console.log("before------------",ind,lastTransList.length-1)
                try{
                    response= await CustomAxios.post("oasis/bulkTrans/",load)
                    return response.data;
                }
                catch(err){
                    console.log(err)
                }     
            })
            // console.log(newRes)
            const fetchedResults = await Promise.all(newRes);
            console.log(fetchedResults)
            
            setBulktrans(fetchedResults.flat())
            setlastTransList([])
        }else{
            setfinalMembers([])
             let payloadbulk
             let load
             console.log("estateno")
            if (searchGlobalBulkNo!==""){
                const estateno= estateArray.filter(item=>item.estatename==searchGlobalBulkNo)
                if(estateno.length>0){
                    payloadbulk={estateid:estateno[0].estateid,bulkNo:bulkNo}
                    load={transmaster:searchGlobalBulkNo}
                }else{
                    payloadbulk={bulkNo:searchGlobalBulkNo}
                    load={transmaster:searchGlobalBulkNo}
                }
            }else if(receipt=='receipt'){
                payloadbulk={receiptNo:rowDate.transmasterid}
            }
            else{
                payloadbulk={bulkNo:rowDate.transmasterid}
                load={transmaster:rowDate.transmasterid}
            }
                // else if(receiptNo){
                //         payloadbulk={receiptNo:receiptNo}
                //     }
                if (searchGlobalBulkNo!==""){
                    if(!estateList.includes(searchGlobalBulkNo)){
                        setfinalMembersSearched([])
                    }
                    bulkList= await CustomAxios.post(`oasis/bulklist/?page=${estatePageNo}`,payloadbulk)
                    setfinalMembersSearched(estatefilter(bulkList.data.memberList))
                }
                else{
                    bulkList= await CustomAxios.post(`oasis/bulklist/`,payloadbulk).then((data)=>data.data.memberList[0]) 
                    response= await CustomAxios.post("oasis/bulkTrans/",load)
                    if(bulkList.chequedate){
                    //     const dateformat=bulkList.chequedate.split("T")
                    // dateObject = new Date(dateformat[0]);
                    // const day = dateObject.getDate();
                    // const month = dateObject.getMonth() + 1; 
                    // const year = dateObject.getFullYear();
                    // formattedDate = `${day < 10 ? '0' : ''}${day}-${month < 10 ? '0' : ''}${month}-${year}`;
                       formattedDate = dayjs(bulkList.chequedate.split("T")[0]).format("DD-MM-YYYY")
                    }
                    console.log(response)
                    let sorted = response.data
                    setBulktrans(sorted)
                    }
                
        if(action=="dup"||action=="edit"){
            const spacesNeededForCoop = 50 - bulkList.coop_bank.length; 
            const spacesNeededForBank = 50 - bulkList.bankname.length; 
                setNarration(bulkList.narration)
                spacesNeededForBank <= 0?setBank(bulkList.bankname):setBank(bulkList.bankname+ " ".repeat(spacesNeededForBank))
                spacesNeededForCoop <= 0?setCoopbank(bulkList.coop_bank):setCoopbank(bulkList.coop_bank+ " ".repeat(spacesNeededForCoop))
                setDate(dayjs(bulkList.chequedate, "YYYY/MM/DD"))
                setEstate(bulkList.estateid)
                setBulkAmount(bulkList.transamount)
                setCheque(bulkList.chequeno+" "+bulkList.bankname)
                setNarration(bulkList.narration)
                setChequeNo(bulkList.chequeno)
                setManager(bulkList?.estatemanagername.trim())}
        if(action=="dup"){

            setPayload ({
                bankname: bulkList.bankname,
                coopbank:bulkList.coop_bank,
                date: formattedDate,
                estate: bulkList.estatemanagername,
                state: bulkList.estatemanagername,
                transamount: bulkList.transamount,
                narration: bulkList.narration,
                currentState: bulkList.estatemanagername,
                chequeno: bulkList.chequeno,
                estatemanagername: bulkList.estatemanagername.trim(),
            })

        }
        else if(action=="edit"){
            setPayload ({
                bankname: bulkList.bankname,
                coopbank:bulkList.coop_bank,
                date: formattedDate,
                estate: bulkList.estatemanagername,
                state: bulkList.estatemanagername,
                transamount: bulkList.transamount,
                narration: bulkList.narration,
                currentState: bulkList.estatemanagername,
                chequeno: bulkList.chequeno,
                estatemanagername: bulkList.estatemanagername,
                bulkNo:bulkList.transmasterid
            })
        }}
        setpayloadStatus(true)
        setModalOpen(false)
    }

    const handleRemoveMember = async(rowData,data) => {
        //console.log(rowData)
        setDeletemodal(true)
        setRowDataToDelete(rowData)
        setRowIndexToDelete(data)
        
            
    }
    const deleteMem=(rowData,rowIndex)=>{
       return <Button  sx={{color:"red"}} onClick={() => handleRemoveMember(rowData,rowIndex)}>Remove</Button>
    }
   const selectBulk=(rowDate)=>{
    return (< Box sx={{display:"flex",gap:"30px"}}> <Tooltip title="Duplicate" arrow><IconButton  onClick={()=>SelectedBulk(rowDate,"dup")}><ContentCopyIcon/></IconButton></Tooltip>
<Tooltip title="View & Edit" arrow><IconButton  onClick={()=>SelectedBulk(rowDate,"edit")}><ModeEditIcon/></IconButton></Tooltip>

    </Box>)
   }
   const handleFetchAndPrintReceipt = async (receiptId, type) => {
    try {
        // URL to your Django view that generates the PDF
        let receiptUrl;
        if(type == "single"){
            receiptUrl = `oasis/download-Dreceipt/${receiptId}`;
        }
        else{
            receiptUrl = `oasis/download-Dreceipt-all/${receiptId}`;
        }

        // Fetch the PDF receipt
        const response = await CustomAxios.get(receiptUrl, { responseType: 'blob' });
        if (response.data) {
            // Create a URL for the PDF blob
            const fileURL = URL.createObjectURL(response.data);

            // Open the PDF in a new window/tab
            const win = window.open(fileURL, '_blank');

            // Optionally, automatically trigger the print dialog in the new window
            win.onload = () => {
                win.focus();
                win.print();
            };
        }
    } catch (error) {
        console.error("Error fetching the receipt: ", error);
    }
};

   const PrintData = (rowDate, rowIndex)=>{
    return(
        <Box sx={{display:"flex",gap:"15px",alignItems:"center"}}>
            {rowDate.receiptid && <Tooltip title="Print" arrow><PrintIcon style={{cursor:"pointer"}} onClick={() => handleFetchAndPrintReceipt(rowDate.receiptid, "single")} /></Tooltip>}
            <Tooltip title="Delete Transaction" arrow><IconButton  sx={{color:"red"}} onClick={() => handleRemoveMember(rowDate,rowIndex)}><DeleteOutlineIcon/></IconButton></Tooltip>
        </Box>
    )
   }
   const formattingdate = (date) => {
    ////console.log(date)
    if (date) {
        // const originalDate = new Date(date.transdate);
        // const year = originalDate.getFullYear();
        // const month = String(originalDate.getMonth() + 1).padStart(2, '0');
        // const day = String(originalDate.getDate()).padStart(2, '0');

        // let formattedDate = `${day}-${month}-${year}`;
        let formattedDate = dayjs(date.transdate.split("T")[0])?.format("DD-MM-YYYY")
        return formattedDate;
    }
    else {
        return date.transdate
    }

}
   

    // const [columnVisible, setColumneVisible] = useState([
    //     { field: "receiptid", header: "RN", visible: true},
    //     { field: "memberid", header: "Member No", visible: true },
    //     { field: "membername", header: "Member Name", visible: true },
    //     { field: "estate", header: "Estate Name", visible: true },
    //     // { field: "transmasterid", header: "Transaction", visible: true },
    //     { field: "narration", header: "Narration", visible: true },
    //     { field: "amount", header: "Amount (RM)", visible: true },
    //     { field:"transtypeid",header:"Trans Type",visible:true,body:TransType},
    //     { field: "loanid", header: "LN/SD/BR no", visible: true },
    //     { field: "sharebalance", header: "Curt Share Bal.", visible: true ,body:decimalshare},
    //     { field: "subscriptionbalance", header: "Curt Subs Bal.", visible: true,body:decimalsub },
    //     { field: "loanbalance",header:"Loan Bal.", visible:true,body:decimalloan}, 
    // ])
    const [columnVisible, setColumneVisible] = useState([
        { field: "receiptid", header: "RN", visible: true,frozen:true,style: { pointerEvents: 'none' } },
        { field: "memberid", header: "M.NO", visible: true,frozen:true ,style: { pointerEvents: 'none' } },
        { field: "membername", header: "Member Name", visible: true ,frozen:true,style: { pointerEvents: 'none' } },
        { field: "estate", header: "EstateName", visible: true ,style: { pointerEvents: 'none' } },
        // { field: "transmasterid", header: "Transaction", visible: true },
        { field: "narration", header: "Narration", visible: true ,pointer: true,style: { pointerEvents: 'auto'}},
        { field: "loanid", header: "LN/SD/BR", visible: true,style: { pointerEvents: 'none' }  },
        { field: "amount", header: "Amt (RM)", visible: true ,style: { pointerEvents: 'auto' }},
        { field: "loanbalance",header:"LoanBal", visible:true,body:decimalloan,style: { pointerEvents: 'none' } },
        { field: "sharebalance", header: "Current Share Bal (RM)", visible: true ,body:decimalshare,style: { pointerEvents: 'none' } },
        { field: "subscriptionbalance", header: "Current SUBS Bal (RM)", visible: true,body:decimalsub ,style: { pointerEvents: 'none' } },
        { field:"transtypeid",header:"TransType",visible:true,body:TransType,style: { pointerEvents: 'none' } },
    ])

    const exportColumns = columnVisible.map((col) => ({ title: col.header, dataKey: col.field }));
    exportColumns.splice(0,0,{title:"S/N", dataKey:"SN"})
    ////console.log(exportColumns)
    const renderHeader = (column) => {
        return (
            <div >
                <span style={{position:"absolute",right:"30px"}}>{column.sortable}</span>
            </div>
        );
    };
    // const [bulkColumnVisible, setBulkColumneVisible] = useState([
    //     { field: "transmasterid", header: "Bulk #", sortable: true,  visible: true},
    //     { field: "receiptid", header: "RN", sortable: true,  visible: true},
    //     { field: "memberid", header: "Member #", sortable: true,  visible: true },
    //     { field: "membername", header: "Member Name", sortable: true,  visible: true },
    //     { field: "estate", header: "Estate Name", sortable: true,  visible: true },
    //     { field: "narration", header: "Narration", sortable: true,  visible: true },
    //     { field: "loanid", header: "LN/SD/BR", sortable: true,  visible: true },
    //     { field: "amount", header: "Amt in RM", sortable: true,  visible: true },
    //     { field: "loanbalance",header:"Loan Bal.", sortable: true,  visible:true,body:decimalloan},
    //     { field: "sharebalance", header: "Share Bal.", sortable: true,  visible: true ,body:decimalshare},
    //     { field: "subscriptionbalance", header: "Subscription Bal.", sortable: true,  visible: true,body:decimalsub },
    //     { field:"transtypeid",header:"TransType", sortable: true, visible:true,body:TransType},
       
        
    // ])
    const [filters, setFilters] = useState({
        transmasterid: { value: null, matchMode: FilterMatchMode.CONTAINS },
        transdate: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        estatenamefull: { value: null, matchMode: FilterMatchMode.CONTAINS },
        estatemanagername: { value: null, matchMode: FilterMatchMode.CONTAINS },
        transamount: { value: null, matchMode: FilterMatchMode.EQUALS },
    });
    const [bulkColumnVisible, setBulkColumneVisible] = useState([
        
        { field: "transmasterid", header: "Bulk No", visible: true ,style:{width:"100px"},filter: true},
        { field: "transdate", header: "Date", visible: true, body:formattingdate, style:{width:"200px"} },
        { field: "estatenamefull", header: "Estate Name", visible: true,style:{width:"200px"},filter: true,},
        { field: "estatemanagername", header: "Manager Name", visible: true ,style:{width:"200px"},filter: true,},
        { field: "transamount", header: "Bulk Amount", visible: true,style:{width:"100px"} ,filter: true,},
        { field: "bankname", header: "Bank Name", visible: true,style:{width:"100px"}},
        { field:"chequeno",header:"Cheq No",visible:true,style:{width:"100px"}},
        { field:"transmasterid",header:"Actions", visible:true,body:selectBulk ,style:{width:"100px"}}
       
        
    ])

    const exportBulkColumns = bulkColumnVisible.map((col) => ({ title: col.header, dataKey: col.field }));
   
    const clearform = (e) => {
        e.preventDefault()
        setCurrentMember([])
        setMemberId("")
        setOldNric("")
        setNewNric("")
        setMemberName("")
        setAmount("")
        setTransType("")
        setLoanid("")
        setLoanEnable(true)
        // setBulkNo("")
        // setReceiptNo("")
        // setAction("")
    }
    const clearFormMaster = (e) => {
        e.preventDefault()
        setBank("")
        setDate(null)
        setEstate("")
        setNarration("")
        setBulkAmount("")
        setCurrentState("")
        setChequeNo("")
        setCoopbank("")
        setCheckbox(false)
        setBulkNo("")
        setReceiptNo("")
        setAction("")
        setManager("")
        setfinalMembers([])
        setEditDetails(false)
        // setBulkList("")
        setTotal("")
        setResponse([])
        setPayload ({
            bankname: "",
            coopbank:"",
            date: null,
            estate:"",
            state: "",
            transamount: "",
            narration: "",
            currentState: "",
            chequeno: "",
            estatemanagername: "",
            bulkNo:""
        })
        errors.member=""
        errors.share=""
        errors.depositDate=""
    }

    const exportReceipt = (e) => {
        setAnchorEl(null)
        // PURPOSE OF THIS FUNCTION. IN FUTURE WE CAN ENABLE DOWNLOAD OR PRINT OPTION HERE
        if(payload && payload.bulkNo){
            handleFetchAndPrintReceipt(payload.bulkNo, "multiple")
        }
    }
    useEffect(()=>{
        if (finalMembers.length>0){
            // //console.log(finalMembers)
            const members=finalMembers.map(item=>{
                item.narration=narration
                return item
            })
            ////console.log(members)
        }
    },[narration])
////console.log(date,newDate)
    const handleSave =async (e) => {
        e.preventDefault()

        if(date && date.$d == "Invalid Date") {
            setIsSubmitted(true)
            setErrors(prev => ({...prev, depositDate: !date ? "Deposit Date is required" : "Invalid Date"}))
            return
        }
        else if(isFutureDateError) {
            setIsSubmitted(true)
            setErrors(prev => ({...prev, depositDate: "Future dates not allowed. Enter only past or today date."}))
            return
        }
        setIsSubmitted(false)
        setErrors(prev => ({...prev, depositDate: ""}))

        const estateno=estateArray.filter(item=>item.estatename==estate)
        let formattedDate = date?.format("YYYY-MM-DDTHH:mm:ss[Z]");
    //     if(typeof(date)!=="string"){
    //         let dateObj = new Date(date[0])
    //     const year = dateObj.getFullYear();
    //     const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    //     const day = String(dateObj.getDate()).padStart(2, '0');
    //     const hours = String(dateObj.getHours()).padStart(2, '0');
    //     const minutes = String(dateObj.getMinutes()).padStart(2, '0');
    //     const seconds = String(dateObj.getSeconds()).padStart(2, '0');
    //     formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`
    // }
        payload.chequedate =typeof(date)!=="string"?formattedDate:date
        const FinalPayload = {
            ...payload,
            estateid:estateno[0].estateid,
            transdetails:finalMembers,
            
        }
        let response
        ////console.log(payload)

        // payload={...payload,transdetails:finalMembers}
        setLoading(true)
        if(action=="dup"){
            try{
            response=await CustomAxios.post('oasis/deposit/',FinalPayload)
            if(response.data.success){
                setDate(dayjs(response.data.success.chequedate, "YYYY/MM/DD"))
                setSucces(true)
                response.data.success.transdetails.map((item)=>{
                    let transExists = finalmembersLastTrans.some(obj => obj.receiptid == item.receiptid);
                    if(!transExists){
                    setfinalMembersLastTrans((previous)=>{return [...previous, item].sort((a,b)=>{
                        if (a.transmasterid > b.transmasterid) return -1;
                                if (a.transmasterid < b.transmasterid) return 1;
    
                                // If masterids are equal, sort based on id in ascending order
                                if (a.receiptid > b.receiptid) return 1;
                                if (a.receiptid < b.receiptid) return -1;
    
                                return 0;
                    })})
                }
                })
                setTransdetails(response.data.success)
                setfinalMembers([])
                setResponse(response.data.success)
                setPayload((payload=>{
                return {...payload,bulkNo:response.data.success.masterid}}))
                setLoading(false)
                setChequeNo(response.data.success.chequeno)
                setBank(response.data.success.bankname)
                setLoading(false)
            }
            else{
                setApiError(true)
                setLoading(false)
            }}
            catch(err){
                console.log(err)
                setApiError(true)
                setLoading(false)
            }
        }
        else if(action=="edit"){
            try{
                response=await CustomAxios.post('oasis/edit/',FinalPayload)
            if(response.data.success){
                setDate(dayjs(response.data.success.chequedate, "YYYY/MM/DD"))
                setTransdetails(response.data.success)
                setfinalMembers([])
                setResponse(response.data.success)
                setPayload((payload=>{
                return {...payload,bulkNo:response.data.success.masterid}}))
                setLoading(false)
                setChequeNo(response.data.success.chequeno)
                setBank(response.data.success.bankname)
                setLoading(false)
            setSucces(true)
            setLoading(false)
            }
            else{
                setApiError(true)
                setLoading(false)
            }}
            catch(err){
                console.log(err)
                setApiError(true)
                setLoading(false)
            }
            
        }
        else{
            try{
                response=await CustomAxios.post('oasis/deposit/',FinalPayload)
                if(response.data.success){
                     setDate(dayjs(response.data.success.chequedate, "YYYY/MM/DD"))
            setSucces(true)
            response.data.success.transdetails.map((item)=>{
                let transExists = finalmembersLastTrans.some(obj => obj.receiptid == item.receiptid);
                if(!transExists){
                setfinalMembersLastTrans((previous)=>{return [...previous, item].sort((a,b)=>{
                    if (a.transmasterid > b.transmasterid) return -1;
                            if (a.transmasterid < b.transmasterid) return 1;

                            // If masterids are equal, sort based on id in ascending order
                            if (a.transdetailid > b.transdetailid) return 1;
                            if (a.transdetailid < b.transdetailid) return -1;

                            return 0;
                })})
            }
            })
            setTransdetails(response.data.success)
            setfinalMembers([])
            setResponse(response.data.success)
            setPayload((payload=>{
            return {...payload,bulkNo:response.data.success.masterid}}))
            setLoading(false)
            setChequeNo(response.data.success.chequeno)
            setBank(response.data.success.bankname)
            setLoading(false)
                }
            else{
                setApiError(true)
                setLoading(false)
            }}
            catch(error){
                console.error('Error fetching data:', error);
                setApiError(true)
                setLoading(false)
            }
        }
        ////console.log(response)
        
        // setDate(response.data.success.chequedate)
    }
    useEffect(()=>{  
        console.log("current")    
        if(currentMember.length > 0){
            // setShouldFocus(true)
            const filtered = finalMembers.filter((mem)=>mem.memberid == currentMember[0].memberid)
            if(currentMember.length>0){
                (currentMember[0].sharebalance<1200||(memberShareCheck.length>0&&memberShareCheck[0].updatedShareBal<1200))?setTransType(1):setTransType(2)}
            setMemberSharecheck(filtered)
        }
        // else{
        //     setShouldFocus(false)
        //     console.log(receiptModal)
        //     if(receiptModal==true){
        //         console.log("currentsetShouldFocusmember")    
        //         setShouldFocusmember(true)
        //     }
        // }
        if(memberid){
            setAmount("")
        }
        // if(transtype!=""){
        //     setShouldFocus(true)
        // }
    },[currentMember,memberid]) 

    useEffect(() => {
        if(isFetchedNewMember && memberid && memberName && (newNric || currentMember.length>0 && currentMember[0].oldicno)) {
            inputRef.current.focus()
            setIsFetchedNewMember(false)
        }
    }, [isFetchedNewMember])

    useEffect(()=>{
        console.log(transtype, loanid)
        if ((transtype==5 && loanid!=null) || transtype==7){
            console.log("here")
            setShouldFocusAddList(true)
        }
    }, [transtype, loanid])

    useEffect(()=>{
        if (shouldFocusAddList==true && inputRefadd.current){
            setShouldFocusAddList(false)
      inputRefadd.current.focus();
        }
    }, [shouldFocusAddList])
    const handleAdd = async(e) =>{
        e.preventDefault()
        if(finalMembers.length > 0){
            const repeatedTransaction = finalMembers.filter((member)=>{
               if(loanid!==""){
                return member.memberid==currentMember[0].memberid&&member.transtypeid == transtype&&member.loanid==loanid
               }else{
                   return member.memberid==currentMember[0].memberid&&member.transtypeid == transtype
               }
            }
        )
        console.log(repeatedTransaction)
        if(repeatedTransaction.length>0){
            setRepeatedTransModal(true)
        }else{
            addToFinalmembers(e)
        }
        }else{
            addToFinalmembers(e)
        }
        
    }
    const addToFinalmembers = async(e) =>{
        e.preventDefault()
        errors.share= ""
        let updated = 0
        const filteredMem = finalMembers.filter((member)=>member.memberid==currentMember[0].memberid)
        
        ////console.log(filteredMem.length>0,amount!=="",transtype==1)
        if(filteredMem.length>0&&handleFormattedAmount(amount)!==""&&transtype==1){
           if(((filteredMem[filteredMem.length-1].updatedShareBal)+parseFloat(handleFormattedAmount(amount))<=1200)||((filteredMem[filteredMem.length-1].sharebalance)+parseFloat(handleFormattedAmount(amount))<=1200)){
            errors.share= ""
            ////console.log(((filteredMem[filteredMem.length-1].updatedShareBal),parseInt(amount)<=1200))
                   setFilteredMem(filteredMem)
                   updated = filteredMem[filteredMem.length-1].updatedShareBal?parseInt(filteredMem[filteredMem.length-1].updatedShareBal)+parseFloat(handleFormattedAmount(amount)):parseInt(filteredMem[filteredMem.length-1].sharebalance)+parseFloat(handleFormattedAmount(amount))
                   ////console.log(updated)
                   filteredMem.map((data)=>
                   data.updatedShareBal=updated
                   )
           }else{
               errors.share= "exceededSharebalance"
           }
        }
        ////console.log("updated",updated,errors)
        // let loan=""
        // ////console.log(transtype)
        if(transtype==5){
            // loan=await axios.post(api+"loanDetails/",{memberid:currentMember[0].memberid})
            ////console.log(loan.data.success.loanid)
            currentMember[0].loanid=loanid
        }
        else{
            currentMember[0].loanid=""
        }
        // if(loan!=""){
        //     currentMember[0].loanid=loan.data.success.loanid
        // }
        // else{
        //     currentMember[0].loanid=""
        // }
                
        if(finalMembers.length==0||(updated<=1200&&errors.share=="")||transtype==2){
        currentMember[0].transtypeid = transtype
        currentMember[0].updatedShareBal =finalMembers.length!==0&&updated!=0? updated:transtype==1?parseInt(currentMember[0].sharebalance)+parseFloat(handleFormattedAmount(amount)):filteredMem.length!==0?filteredMem[0].updatedShareBal:0
        currentMember[0].updatedSubscriptionBal = parseInt(currentMember[0].subscriptionbalance)+parseFloat(handleFormattedAmount(amount))
        currentMember[0].narration = narration
        currentMember[0].amount = handleFormattedAmount(amount)
        currentMember[0].transamount =parseFloat(handleFormattedAmount(amount))
        currentMember[0].bankname=bank
        currentMember[0].createdby = localStorage.getItem('username')
        
        ////console.log(currentMember[0].memberid)
       
        ////console.log(finalMembers)
        ////console.log(currentMember)
        setfinalMembers([...finalMembers,currentMember[0]])}
        setCurrentMember([])
        clearform(e)
        setFilteredMem([])
        setRepeatedTransModal(false)
        inputRefMemId.current.focus()
    }
    const searchBulk= async()=>{
        ////console.log(estate)
        let load
        if (searchEstate){
            const estateno= estateArray.filter(item=>item.estatename==searchEstate)
            load={estateid:estateno[0].estateid,bulkNo:bulkNo}
        }
        else if (bulkNo){
            load={bulkNo:bulkNo}
        }
        else if(receiptNo){
            load={receiptNo:receiptNo}
        }
        ////console.log(load)
        const bulkList= await CustomAxios.post("oasis/bulklist/",load)
        const sortedArray = bulkList.data.memberList.sort((a, b) => new Date(b.transdate) - new Date(a.transdate));
        //console.log(bulkList.data.memberList)
        //setBulkList(sortedArray)
    }
    useEffect(()=>{
    let count = 0

     if(transdetails.transdetails?.length > 0){
       let updatepayment=[...finalMembers]
   transdetails.transdetails.map((data)=>{
        //console.log(count)
        //console.log(updatepayment[count])
        const member =  transdetails.members.find(mem => mem.memberid === data.memberid);
       
       //console.log(res.data.subscriptionbalance)
       //console.log(res.data.loanbalance)
    //    updatepayment[count].receiptid = data.receiptid
    //    updatepayment[count].transmasterid = data.transmasterid
    const res = {...member}
        if(res){

            data.sharebalance = res.sharebalance
             
            data.subscriptionbalance = res.subscriptionbalance
     
             
            data.loanbalance = res.loanbalance
     
     
     
             
            count=count+1
            
             
             // count=count+1
             setfinalMembers((previous=>[...previous,data]))
        }
  
   
    })}
    },[transdetails])
    useEffect(()=>{
        if(finalMembers.length > 0){
            const total=finalMembers.reduce((total,data)=>total+parseInt(data.amount),0)
        //console.log(total)
        //console.log(total)
        setTotal(total) 
        setBL(handleFormattedAmount(bulkAmount)-total)
        }
    },[amount,finalMembers, bulkAmount])
   
    const checkDOJ=(doj)=>{
        const date = new Date(doj.doj);
        const today = new Date();
        ////console.log(doj.doj)
        ////console.log(date)
        const differenceInMilliseconds = today - date;
        const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);
        ////console.log(differenceInDays)
        if (differenceInDays <= 7) {
            ////console.log("we are here")
            return true;
        } else {
            ////console.log("we are not here")
            return false;
        }

    }
    
    const GetMemberDetails = async(e, ref)=>{
        e.preventDefault()
        if((ref === inputRefMemId && (e.target.value.trim() !== "" && !isNaN(e.target.value.trim()) && Number.isInteger(parseInt(e.target.value.trim())))) || e.target.value.trim() !== "") {
            let payload
            if (ref === inputRefMemId) {
                payload = {
                    "memberid":parseInt(e.target.value),
                    'type':'receipt'
                }        
            } else if (ref === inputRefMemName){
                payload = { "name":e.target.value }
            }
            else if (ref === inputRefNewNric){
                payload = { "icno":e.target.value }
            }

        // if(!isNaN(e.target.value) && Number.isInteger(parseInt(e.target.value))||(newNric!="")||(memberName!="")){
            // let payload
            // if (ref === inputRefMemId && memberid!=""){
            //     ////console.log(memberid)
            //     payload = {
            //         "memberid":parseInt(memberid),
            //         'type': 'receipt'
            //     }
            // } else if (ref === inputRefMemName && memberName!=""){
            //     payload={
            //     "name":memberName}
            // }
            // else if (ref === inputRefNewNric && newNric!=""){
            //         ////console.log(newNric)
            //         payload={
            //         "icno":newNric}
            //         ////console.log(payload)
            // }
            var result
            try{
                errors.member= "";
                // setLoading(true);
                result = await CustomAxios.post(`oasis/getmember/`, payload).then((data)=>{
                    // //console.log(data)
                    let res_data = data.data

                    if(res_data?.message === "No Member Found") {
                        setMemberId("")
                        setMemberName("")
                        setNewNric("")
                        setOldNric("")
                        setCurrentMember([])
                        errors.member = (ref === inputRefMemId?`Member Id "${e.target.value}" does not exist.`:ref === inputRefMemName?`Member Name "${e.target.value}" does not exist.`:ref === inputRefNewNric && `Member New NRIC "${e.target.value}" does not exist.`)
                        return false
                    } else {
                        CheckEntryFee(data.data.memberid)
                        setCurrentMember([res_data])
                        setNewNric(data.data.newicno)
                        setMemberName(data.data.membername)
                        setMemberId(data.data.memberid)
                        setIsFetchedNewMember(true)
                        // setShouldFocus(false)
                        // setLoading(false);

                        if(ref.current) {
                            ref.current.hide()
                        }
                        return true
                    }
                    
                }).catch(err=>{
                    //console.error(err)
                    // setLoading(false);
                // errors.member= "not available";
                    
                })
            }
            catch(err){
                //console.error(err)
                // errors.member= "not available";
            }
        }
        else{
            setCurrentMember([])
        }

        if(!result)
            return

        try {
                let payload = { memberid: memberid };
                if(memberid){
                    const response = await CustomAxios.get(`oasis/specificGuarantor/?mid=${parseInt(memberid)}`);
                    ////console.log("gggggggggggggggg", response.data);
                    let activeLoans = response.data.loan.filter((loan)=>loan.loanbalanceamount!=="0.00")
                    setLoanList(activeLoans)
                }
                else if(memberName){
                    let member = memberList.filter((mem)=>
                    mem.membername==memberName
                )
                    let memberid = member[0].memberid
                    const response = await CustomAxios.get(`oasis/specificGuarantor/?mid=${parseInt(memberid)}`);
                    let activeLoans = response.data.loan.filter((loan)=>loan.loanbalanceamount!=="0.00")
                    setLoanList(activeLoans)
                }else{
                    let member = memberList.filter((mem)=>
                    mem.newicno==newNric
                )
                    let memberid = member[0].memberid
                    const response = await CustomAxios.get(`oasis/specificGuarantor/?mid=${parseInt(memberid)}`);
                    let activeLoans = response.data.loan.filter((loan)=>loan.loanbalanceamount!=="0.00")
                    setLoanList(activeLoans)
                }
                
            } catch (error) {
                console.error("Error fetching member details:", error);
            }
    }
    const handleClickIdNameNric = (e, ref) => {
        e.preventDefault()
        if(e.key=="Enter")
            GetMemberDetails(e, ref)
    }

    // const handleclickid = (e) => {
    //     e.preventDefault()
    //     if(e.key=="Enter"){
    //         console.log("******* Member ID: *******", e.target.value)
    //         if(inputRefMemId.current) {
    //             inputRefMemId.current.hide()
    //         }
    //         if(inputRef.current)
    //            inputRef.current.focus();
    //         console.log(memberid)
    //         GetMemberDetails(e)
    //     }
    // }
    // const handleclickname = (e) => {
    //     e.preventDefault()
    //     if(e.key=="Enter"){
    //         if(inputRefMemName.current) {
    //             inputRefMemName.current.hide()
    //         }
    //         console.log(memberid)
    //         GetMemberDetails(e)
    //     }
    // }
    // const handleclickic = (e) => {
    //     e.preventDefault()
    //     if(e.key=="Enter"){
    //         if(inputRefNewNric.current) {
    //             inputRefNewNric.current.hide()
    //         }
    //         console.log(memberid)
    //         GetMemberDetails(e)
    //     }
    // }
    const handleUpdate = (e) => { 
        if(e.key=="Enter"){
            e.preventDefault()
        }
        // if(e.key=="Enter"){
        //     if(updatedTable.data[updatedTable.event.index].sharebalance>=1200){
        //         console.log(updatedTable,transtype)
        //         if(updatedTable.data[updatedTable.event.index].transtypeid==1){
        //             updatedTable.data[updatedTable.event.index].transtypeid = transtype;
        //             updatedTable.data[updatedTable.event.index].amount = parseInt(updatedTable.event.value);
        //             updatedTable.data[updatedTable.event.index].transamount = parseInt(updatedTable.event.value);;
        //         }
        //     }
        // }
        if(updatedTable!==""&& e.key=="Enter"&&borderColor=="green"&&updatedTable.event.data.transtypeid==1){
            setBorderColor('green')
            if (updatedTable.event.field=="amount"){
            ////console.log((parseInt(updatedTable.event.data.updatedShareBal)-parseInt(updatedTable.event.data.transamount))+parseInt(updatedTable.event.value))

            updatedTable.data[updatedTable.event.index].updatedShareBal =updatedTable.event.data.updatedShareBal?(parseInt(updatedTable.event.data.updatedShareBal)-parseInt(updatedTable.event.data.transamount))+parseInt(updatedTable.event.value):(parseInt(updatedTable.event.data.sharebalance)-parseInt(updatedTable.event.data.transamount))+parseInt(updatedTable.event.value)
            updatedTable.data[updatedTable.event.index].amount = parseInt(updatedTable.event.value);
            updatedTable.data[updatedTable.event.index].transamount = parseInt(updatedTable.event.value);
            const filteredMem = finalMembers.filter((member)=>member.memberid==updatedTable.data[updatedTable.event.index].memberid)
                filteredMem.map((data)=>
                data.updatedShareBal=updatedTable.data[updatedTable.event.index].updatedShareBal
                )
                ////console.log(updatedTable)
            setfinalMembers(updatedTable.data)}
        }
        else if(updatedTable!==""&&e.key=="Enter"&&borderColor=="green"&&(updatedTable.event.data.transtypeid==2||updatedTable.event.data.transtypeid==5)){
            if(updatedTable.event.field=="amount"){
                 updatedTable.data[updatedTable.event.index].amount = updatedTable.event.value; 
            updatedTable.data[updatedTable.event.index].transamount = parseInt(updatedTable.event.value);
            ////console.log(updatedTable)
            }
           
            if(updatedTable.event.field=="narration"){
                ////console.log("updatedfinal",e)
                updatedTable.data[updatedTable.event.index].narration =updatedTable.event.value;

            }
            setfinalMembers(updatedTable.data)
        }
        setBorderColor("") 
        setUpdatedTable("")
    }
    const onEditorValueChange = (e) => {
        let updatedfinal = [...finalMembers];
        // //console.log((total+parseInt(e.value)-updatedfinal[e.index].transamount)<=parseInt(bulkAmount))
        //console.log((Total),e.value,parseInt(e.data.transamount))
        if(e.field=="narration"||(Total+parseInt(e.value)-parseInt(e.data.transamount))<=parseInt(handleFormattedAmount(bulkAmount))){
        if(((updatedfinal[e.index].updatedShareBal)-parseInt(updatedfinal[e.index].transamount)<1200||(updatedfinal[e.index].sharebalance)-parseInt(updatedfinal[e.index].transamount)<1200 )&& updatedfinal[e.index].transtypeid==1){
            if(updatedfinal[e.index].updatedShareBal?((parseInt(updatedfinal[e.index].updatedShareBal)-parseInt(updatedfinal[e.index].transamount))+parseInt(e.value))<1201:((parseInt(updatedfinal[e.index].sharebalance)-parseInt(updatedfinal[e.index].transamount))+parseInt(e.value))<1201){
                ////console.log("inside share")
                setBorderColor('green')
                setUpdatedTable({data:updatedfinal,event:e});
            }
            else if(e.field=="narration"){
                setBorderColor('green')
                updatedfinal[e.index].narration = e.value;
                setUpdatedTable({data:updatedfinal,event:e})
            }
            else{
                setBorderColor('red')
            }
        }
        else if(updatedfinal[e.index].transtypeid==2||updatedfinal[e.index].transtypeid==5){
            //console.log("inside subs okkkk")
            setBorderColor('green')
            setUpdatedTable({data:updatedfinal,event:e});

            
        }}
        else{
            ////console.log(e.value,"===============================")
            setBorderColor('red')
            updatedfinal[e.index].transamount = finalMembers[e.index].transamount;
            updatedfinal[e.index][e.field] = finalMembers[e.index].amount;
        }

      };
     
    const inputEditor = (props) => {
    if((Object.keys(response).length==0 &&props.field=="amount")||(Object.keys(response).length==0 && props.field=="narration")){
        // setBorderColor("green")
        const inputStyle = {
            border: `1px solid ${borderColor}`,
            outline: `1px solid ${borderColor}`,
            height:"30px",
            borderRadius:"4px"
          };
        return (
            <InputText
            type={props.field=="amount"?"number":"text"}
            //   value={props.value}
            style={inputStyle}
            onKeyDown={(e)=>handleUpdate(e)}
            onChange={(e) => onEditorValueChange({ index: props.rowIndex, field: props.field, value: e.target.value,data:props.props.value[props.rowIndex] })}
            />
          );}
          else {
            return <Box>{props.field=="sharebalance"||props.field=="subscriptionbalance"||props.field=="loanbalance"? parseInt(props.value).toFixed(2):props.field=="transtypeid"?props.value==1?"Share":"Subscription":props.value}</Box>;
          }
      };
      ////console.log(finalMembers)
    //   //console.log(borderColor)
    //   //console.log(updatedTable)
    // const handleMemberChange = (e) => {
    //     setMemberId(e.target.value)
    //     setMemberName("")
    //     setNewNric("")
    // }
    // const handleNricChange = (e) => {
    //     setNewNric(e.target.value)
    //     setMemberName("")
    //     setMemberId("")
    // }
    // const handleNameChange = (e) => {
    //     setMemberName(e.target.value)
    //     setNewNric("")
    //     setMemberId("")
    // }
    const downlaomanagerLetter=()=>{
        setAnchorEl(null)
        if(payload && payload.bulkNo){
            printManagerLetter(payload.bulkNo, )
        }
    }

    const printManagerLetter=async(receiptId, )=>{
        try {
            // URL to your Django view that generates the PDF
            let receiptUrl;
            // if(type == "single"){
                receiptUrl = `oasis/manager_letter/${receiptId}`;
            // }
            
    
            // Fetch the PDF receipt
            const response = await CustomAxios.get(receiptUrl, { responseType: 'blob' });
            if (response.data) {
                // Create a URL for the PDF blob
                const fileURL = URL.createObjectURL(response.data);
    
                // Open the PDF in a new window/tab
                const win = window.open(fileURL, '_blank');
    
                // Optionally, automatically trigger the print dialog in the new window
                win.onload = () => {
                    win.focus();
                    win.print();
                };
            }
        } catch (error) {
            console.error("Error fetching the receipt: ", error);
        }
    }
const getBulk = async() => {
    let payloadbulk = {bulkNo:searchBulkNo}
    let load={transmaster:searchBulkNo}
    const bulkList= await CustomAxios.post(`oasis/bulklist/`,payloadbulk).then((data)=>data.data.memberList[0]) 
    const response= await CustomAxios.post("oasis/bulkTrans/",load)                
    // const dateformat=bulkList.chequedate.split("T")
    // let dateObject = new Date(dateformat[0]);
    // const day = dateObject.getDate();
    // const month = dateObject.getMonth() + 1; 
    // const year = dateObject.getFullYear();
    // let formattedDate = `${day < 10 ? '0' : ''}${day}-${month < 10 ? '0' : ''}${month}-${year}`;
    let formattedDate = dayjs(bulkList.chequedate.split("T")[0]).format("DD-MM-YYYY")
    let sorted = response.data
    const bulkestate=estateArray.filter(item=>item.estateid==bulkList.estateid)
    setBulktrans(sorted)
    setPayload ({
        bankname: bulkList.bankname,
        coopbank:bulkList.coop_bank,
        date: formattedDate,
        estate: bulkestate[0].estatename,
        state: bulkestate[0].state,
        transamount: bulkList.transamount,
        narration: bulkList.narration,
        currentState: bulkList.estatemanagername,
        chequeno: bulkList.chequeno,
        estatemanagername: bulkList.estatemanagername,
        bulkNo:bulkList.transmasterid
    })
    
}
useEffect(()=>{
    console.log(pdflist.length,bulkTrans.length)
    if(bulkHeading=="Download List"&&pdflist.length>0&&bulkTrans.length>0&&pdflist.length==bulkTrans.length){
        console.log("export")
        exportPdf()
    }
    if(bulkHeading=="Print Contribution List"){
        exportContributionList()
    }

},[payload,pdflist])
    const exportPdf = () => {
        setAnchorEl(null)
        import('jspdf').then((jsPDF) => {
            import('jspdf-autotable').then(() => {
                const doc = new jsPDF.default('l', 0, 0);
                doc.addFileToVFS('Arialn.ttf', arial);
                doc.addFileToVFS('cour.ttf', courier);
                doc.addFileToVFS('Arial MT Black.ttf', arialBold);
                doc.addFont('Arialn.ttf', 'Arial','normal');
                doc.addFont('Arial MT Black.ttf', 'ArialBold','normal');
                doc.addFont('cour.ttf', 'courierNew', 'normal');

                const pageWidth = doc.internal.pageSize.getWidth();
                const header1 = 'KOPERASI SERBAGUNA KEBANGSAAN BERHAD (4391)';
                const header2 = 'Bulk Receipt List';
                // Set the title font size, type, and position correctly
                // doc.text('KOPERASI SERBAGUNA KEBANGSAAN BERHAD (4391)', 14, 15); 
                // doc.text('Bulk Receipt list', 14, 15); 
                const header1Width = doc.getTextWidth(header1); // Calculate width of header
                const header2Width = doc.getTextWidth(header2); // Calculate width of header
                const headerFirst = (pageWidth - header1Width) / 2;
                const headerSecond = (pageWidth - header2Width) / 2;
                // doc.text(header1, headerFirst, 10);
                doc.setFontSize(12);
                doc.setFont('helvetica','bold');
                doc.text(header1, headerFirst+15, 10);
                doc.setFontSize(11);
                doc.setFont('courierNew','normal');
                doc.text(header2, headerSecond, 15);
                // doc.text('Deposit Bulk List', 14, 15); // Position the title
            
                const fieldsAndValues = [
                    { field: 'Manager Name', value: payload.estatemanagername },
                    { field: 'BN', value: payload.bulkNo },
                    { field: 'Estate', value: payload.estate },
                    { field: 'Chq Cleared Date', value:payload.date  },
                    { field: 'State', value: payload.state },
                    { field: 'Chq No', value:payload.bankname&&payload.chequeno?payload.bankname.trim()+" "+payload.chequeno.trim():"--"},
                    { field: 'Narration', value: payload.narration?payload.narration:narration }
                  ];
                  

    // Loop through field-value pairs and add to PDF
    let VerticalPosition = 23; // Initial vertical position
    fieldsAndValues.forEach(({ field, value },index) => {
      doc.setFontSize(8);
      const horizondal = index % 2 === 0 ? 7 : 230;
      doc.setFont('Arial', 'normal');
      doc.text(field, field=="BN"?horizondal+16:field=="Chq No"?horizondal+10:field=="Chq Cleared Date"?horizondal-5:horizondal, VerticalPosition); // Field label position
      doc.setFont('helvetica','bold');
      doc.text(`:${" "}${value}`, horizondal+22 , VerticalPosition); // Adjust position as needed
      VerticalPosition += index % 2 === 0 ?0:5; // Increment vertical position for the next line
    });
                // doc.setFont("Times", "bold");
                // doc.setFontSize(16);
                // doc.text("KOPERASI SERBAGUNA KEBANGSAAN BERHAD (4391)", 18, 20, {align: "left"});

                // doc.setFont("Times");
                // doc.setFontSize(14);
                // doc.text("NATIONAL MULTI-PURPOSE CO-OPERATIVE SOCIETY LIMITED", 120, pageHeight - 70, {align: "left"});

                // // Tamil Text Logo
                // // doc.addImage(tamilTextLogoPath, 'PNG', 113, pageHeight - 103, 50, 15); // Adjust size accordingly

                // // Address and Contact
                // doc.setFontSize(11);
                // doc.text("No. 28-1, Jalan USJ 10/1E, Taipan Triangle 47620 Subang Jaya Selangor Darul Ehsan", 120, pageHeight - 118, {align: "left"});
                // doc.text("Tel : 03-5631 5736 / 03-5631 7712   Fax No: 03-5631 8749 Email: kskb4391@gmail.com", 120, pageHeight - 128, {align: "left"});

                // // Drawing a line
                // doc.setDrawColor(0, 0, 0); // Black color
                // doc.setLineWidth(1);
                // doc.line(40, pageHeight - 138, pageWidth - 40, pageHeight - 138);

                // // Transaction Receipt
                // doc.setFont("Times", "bold");
                // doc.setFontSize(18);
                // doc.text("Transaction Receipt", center_x, pageHeight - 163, {align: "center"});
                    
                // Assuming exportColumns is prepared from columnVisible
                // Prepare the data for autoTable based on visible columns
                // const desiredColumnOrder = ['Receipt No', 'M.NO', 'Member Name','Estate Name','Narration',"LN/SD/BR",'Amount (RM)','LoanBal','Current Share Bal (RM)',"Current SUBS Bal (RM)",'TransType']
                const members = JSON.parse(JSON.stringify(finalMembers));
                const startY = 40
                members.map((mem,index)=>{
                    mem["SN"]= index+1
                    mem.amount= parseInt(mem.amount).toFixed(2)
                    mem.membername=mem.membername.toUpperCase()
                    mem.transtypeid=mem.transtypeid==1?"SHARE":mem.transtypeid==2?"SUBS":mem.transtypeid==5?"LOAN":mem.transtypeid==7?"ENT FEE":""})
                    ////console.log(members)
                const options = {
                    startY: startY,
                    theme:'plain',
                    margin: { top:5,right: 5, bottom: 20, left: 5  },
                    headStyles: { 
                        fillColor: 'white',  
                        textColor: 'black',   
                        fontFamily:"Arial",
                        fontStyle:'normal',
                        fontSize:8,
                        valign: 'bottom',
                        halign:'left',
                        lineWidth: {
                            bottom: .3,
                        },
                        lineColor: 'black',
                        
                    },
                    columnStyles: {
                        0:{fontSize:8,cellWidth: 8},
                        1:{fontSize:8,cellWidth: 15},
                        2:{fontSize:8,cellWidth: 15},
                        3:{fontSize:8,cellWidth: 50},
                        4:{fontSize:8,cellWidth: 40},
                        5:{fontSize:8,cellWidth: 16},
                            6:{ halign: 'center',fontSize:8 ,cellWidth: 18},
                            7: { halign: 'right',fontSize:8 ,cellWidth: 16},
                            8: { halign: 'right' ,fontSize:7,cellWidth: 18},
                            9: { halign: 'right',fontSize:7,cellWidth: 35},
                            10: { halign: 'right',fontSize:7 ,cellWidth: 35},
                            11: { fontSize:7 ,cellWidth: 18},
                          },
                    columns: exportColumns,
                    body: members,
                    
                };
                const availableSpace = doc.internal.pageSize.height - 40 - 10; 
                const maxTableHeight = +availableSpace - +doc.autoTable.previous.finalY;
                ////console.log(maxTableHeight)
                let totalamount= members.reduce((acc,mem)=>acc+mem.transamount,0)
                
                doc.autoTable(options);
                const tableHeight = doc.autoTable.previous.finalY - startY;
                const AfterTable = startY + tableHeight + 5;
                const boxWidth = 75; 
                const boxHeight = 11; 
                const xPos = 122; 
                const yPos = AfterTable; 
                doc.rect(xPos, yPos, boxWidth, boxHeight);
                let grandTotalinTable = parseInt(totalamount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
                const textxposition = xPos+10
                const textyposition = yPos+6
                doc.setFont('helvetica','normal');
                doc.setFontSize(8);
                doc.text(`Grand Total : `,textxposition,textyposition);
                doc.setFont('helvetica','bold');
                doc.text(`${" "} ${grandTotalinTable}`,textxposition+38,textyposition);
                const currentDate = new Date();
                const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed, hence the +1
                const day = String(currentDate.getDate()).padStart(2, '0');
                const year = currentDate.getFullYear();
                const totalPages = doc.internal.getNumberOfPages();
                for (let i = 1; i <= totalPages; i++) {
                  doc.setPage(i);
                  doc.setFontSize(8);
                  doc.setFont('helvetica', 'normal')
                  doc.text(`Page ${i} of ${totalPages}`, doc.internal.pageSize.width - 25, doc.internal.pageSize.height - 5);
                  doc.text(`Print Date: ${day}-${month}-${year}`, 5, doc.internal.pageSize.height - 5);
                }
                doc.save('receipts.pdf'); 
                
    
                
            });
        });
        setBulktrans([])
        setPdfList([])
        setBulkHeading("")
    };

    const exportBulkPdf = () => {
        import('jspdf').then((jsPDF) => {
            import('jspdf-autotable').then(() => {
                const doc = new jsPDF.default('l', 0, 0);

                const pageWidth = doc.internal.pageSize.getWidth();
                const pageHeight = doc.internal.pageSize.getHeight();
                const center_x = (pageWidth - 100) / 2;
    
                // Set the title font size, type, and position correctly
                doc.setFontSize(16);
                doc.setFont('helvetica', 'bold');
                doc.text('Deposit Bulk List', 14, 15); // Position the title

                const options = {
                    startY: 20, // Adjust startY as needed to avoid overlapping the title
                    headStyles: {
                        fillColor: 'white', // Sets the background color of the header
                        textColor: 'black', // Sets the text color of the header
                        fontStyle: 'bold', // Makes the header text bold
                        halign: 'center',
                        // Centers header text
                    },
                    columns: exportBulkColumns,
                    body: bulkList,
                };
    
                doc.autoTable(options); // Generate the table with the prepared data
    
                doc.save('Bulk_receipts.pdf'); // Save the PDF
            });
        });
    };

    const CheckEntryFee=async(memberid)=>{
        const payload={memberid:memberid}
        const response = await CustomAxios.post("oasis/getmember/",payload)
            ////console.log(response.data)
                    
            if (response.data.entryfee==true) {
                // Create a URL for the PDF blob
                ////console.log(response.data.entryfee)
                setCheckEntry(true);
            }
            else{
            setCheckEntry(false);
    }
    }

    const exportContributionList = ()=>{
        setAnchorEl(null)
        setIsClLoading(true)
        let api_payload = {
            manager:payload.estatemanagername!==null?payload.estatemanagername.trim():"--",
            estate:payload.estate,
            state:payload.state,
            narration:payload.narration,
            bulkNo:payload.bulkNo,
            chqClearDate:payload.date,
            chqNo:payload.bankname!==""&&payload.chequeno!==""?payload.bankname.trim()+" "+payload.chequeno.trim():"--"
        }
        console.log(api_payload)
        let empty_ValuedKeys = []
        for (let [key,value] of Object.entries(api_payload)){
            if (!value){
                empty_ValuedKeys.push(key)
            }
        }
        try {
            
            if(empty_ValuedKeys.length === 0){
                CustomAxios.post("oasis/download-contributionPdf/",api_payload,{ responseType: 'blob' }).then((res)=>{
                    
                    if (res.data) {
                        // Create a URL for the PDF blob
                        const fileURL = URL.createObjectURL(res.data);
                        //console.log(fileURL)
            
                        // Open the PDF in a new window/tab
                        const win = window.open(fileURL, '_blank');
                    }
                }).catch((err)=>console.error(err))
            }
            else{
                console.error("-----Values Required------",empty_ValuedKeys)
            }
            
        } catch (error) {
            console.error(error)
        }
        setIsClLoading(false)
    }
    const dateformatedStart = (rowData) =>{
        const date = new Date(rowData.loanstartdate)
        const day = date.getDate();
        const month = date.getMonth() + 1; 
        const year = date.getFullYear();
        const formattedDate = `${day < 10 ? '0' : ''}${day}-${month < 10 ? '0' : ''}${month}-${year}`;
        // //console.log(formattedDate)
        return formattedDate;
    }
    const dateformatedEnd = (rowData) =>{
        const date =new Date(rowData.loanexpirydate)
        const day = date.getDate();
        const month = date.getMonth() + 1; 
        const year = date.getFullYear();
        const formattedDate = `${day < 10 ? '0' : ''}${day}-${month < 10 ? '0' : ''}${month}-${year}`;
        // //console.log(formattedDate)
        return formattedDate;
    }
    const [ColumnVisibleLoan, setColumneVisibleLoan] = useState([
        
        { field: "loanid", header: "Loan#", visible: true },
        { field: "loanstartdate", header: "Loan Date", visible: true,body:dateformatedStart },
        { field: "principalamount", header: "Principal", visible: true},
        { field: "noofinstalment", header: "#inst", visible: true },
        { field: "loanexpirydate", header: "Expiry", visible: true ,body:dateformatedEnd},
        { field: "loanbalanceamount", header: "LoanBalance", visible: true},
       
        
    ])
    const handleEnter = (e) => {
        e.preventDefault()
        if(e.key == "Enter"){
            setfinalMembersSearched([])
            SelectedBulk()
        }
       }

       const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = (type) => {
         //console.log(type.type)
         if(type.type =="Duplicate"){
            setEditDetails(true)
            setlastTransList([])
            setResponse([])
            setSearchGlobalBulk("")
            setSearchBulk("")
            setBulkHeading("Duplicate")
            setprintModalButton("Duplicate")
            setPrintModal(true)
        }
        if(type.type =="download"){
            setSearchBulk("")
            setPdfList([])
            setBulkHeading("Download List")
            setprintModalButton("Download")
            setPrintModal(true)
        }
        if(type.type =="bulkreceipt"){
            setBulkHeading("Print Bulk Receipt")
            setSearchBulk("")
            setprintModalButton("Print")
            setPrintModal(true)
        }
        if(type.type =="manager"){
            setBulkHeading("Print Manager Letter")
            setSearchBulk("")
            setprintModalButton("Print")
            setPrintModal(true)
        }
        if(type.type =="edit"){
            setEditDetails(true)
            setResponse([])
            setSearchBulk("")
            setSearchGlobalBulk("")
            setlastTransList([])
            setBulkHeading("Edit Bulk")
            setprintModalButton("Edit")
            setPrintModal(true)
        }
        if(type.type =="contribution"){
            setResponse([])
            setSearchBulk("")
            setSearchGlobalBulk("")
            setlastTransList([])
            setBulkHeading("Print Contribution List")
            setprintModalButton("Print")
            setPrintModal(true)
        }
        setAnchorEl(null);
    };

    const options = [
        { label: 'Share', value: 'Share' },
        { label: 'Subscription', value: 'Subscription' },
        { label: 'Loan Repayment', value: 'Loan Repayment' },
        { label: 'Entrance Fee', value: 'Entrance Fee' },
      ];
      const expandmore = (props) => {
        return <ExpandMoreIcon {...props} />; // Example icon (ExpandMoreIcon)
      };
    const loanids = loanList.map((loan) =>loan.loanid)
    //console.log(response)
    useEffect(()=>{
        if(lastTransList.length > 0){
            console.log("count")
            SelectedBulk()
        }
   },[lastTransList])
       useEffect(() => {
    let cancelled = false;
  
        if(estateArray.length > 0){
    async function fetchData() {
    setLoading(true)
    setLastTrans(true)

    
            const res = await CustomAxios.get(`${process.env.REACT_APP_OASIS_BE_URL}oasis/last_transactions/?page=${pageNo}`)    
        dispatch(updateBulkListPayload(estatefilter(res.data.transactions)))
    setLoading(false)
  
    }
  
    fetchData();
  
    return () => {
      cancelled = true;
    }}
  }, [pageNo,estateArray]);
  useEffect(()=>{
    if(searchGlobalBulkNo!==""){
        SelectedBulk()
    }
  },[estatePageNo])
  const handlePage = (e) =>{
    e.preventDefault()
    if(searchGlobalBulkNo!==""){
        let count = estatePageNo+1
        setEstatePageNo(count)
    }else{
        let count = pageNo+1
        setPageNo(count)
    }
    
  }
  const paginatorLeft = () => {
    return (
            <Button onClick={(e)=>handlePage(e) } sx={{width:"289px"}}>Load more</Button>
    );
};
const paginatorRight = () => {
    return (
        <Box sx={{width:"289px"}}></Box>
    );
};
const handleOptions = (e) =>{
    if(bulkHeading=="Duplicate"){
        clearFormMaster(e)
        SelectedBulk(e,bulkHeading=="Duplicate"&&{action:"dup"})}
    if(bulkHeading=="Download List"){getBulk()}
    if(bulkHeading=="Print Bulk Receipt"){handleFetchAndPrintReceipt(searchBulkNo, "multiple")}
    if(bulkHeading=="Print Manager Letter"){printManagerLetter(searchBulkNo)}
    if(bulkHeading=="Edit Bulk"){
        clearFormMaster(e)
        SelectedBulk(e,bulkHeading=="Edit Bulk"&&{action:"edit"})}
    if(bulkHeading=="Print Contribution List"){getBulk()}

    
    setPrintModal(false)
}
useEffect(()=>{
       if(success==true){
        setTimeout(() => {
            setSucces(false);
        }, 5000);
       }
},[success])
const filterOptions = (event) => {
    const filtered = memberList.filter((option) =>
    option.memberid.toString().startsWith(event.query.trim())
    ).map((mem)=>mem.memberid.toString())
    console.log(filtered)
    setItems(filtered.slice(0, 100));
  };
  const filterOptionsName = (event) => {
    const filtered = memberList.filter((option) =>
    option.membername.toLowerCase().startsWith(event.query.trim().toString().toLowerCase())
    ).map((mem)=>mem.membername)
    console.log(filtered)
    setItems(filtered.slice(0, 100));
  };
  const filterOptionsNric = (event) => {
    const filtered = memberList.filter((option) =>
    option.newicno!==null&&option.newicno.toString().startsWith(event.query.trim())
    ).map((mem)=>mem.newicno)
    console.log(filtered)
    setItems(filtered.slice(0, 100));
  };
  const filterEstate = (event) => {
    const filtered = estateList.filter((option) =>
    option.toLowerCase().startsWith(event.query.trim().toLowerCase())
    )
    console.log(filtered)
    setestateLists(filtered.slice(0, 100));
  };
  const bodyTemplate = (rowData) => {
    return (
        <Box >
            <Typography >{rowData.transmasterid}</Typography>
        </Box>
    );
};
const generateKey = (rowData, index) => {
    return `${index}`;
  };
  useEffect(() => {
    // console.log("inside")

    // if (containerRef.current&&finalMembers.length>0) {
    //     console.log("inside12")
    //   containerRef.current.scrollTop = containerRef.current.scrollHeight;
    // }
  }, [finalMembers]);
  useEffect(()=>{
      if(coopbank!==""&&bank!==""&&chequeNo!==""&&bulkAmount!==""&&date!==null&&estate!==""&&currentState!==""&&manager!==""&&narration!==""){
        console.log("receipt modal")
        setReceiptModal(true)
      }else{
        setReceiptModal(false)
      }
      if(finalMembers.length ==0){
        setTotal(0)
      }
  },[coopbank,bank,chequeNo,bulkAmount,date,currentState,manager,narration,estate,finalMembers])
//   useEffect(() => {
//     if (shouldFocus==true && inputRef.current) {
//         setShouldFocusmember(false)
//       inputRef.current.focus();
//     }
//   }, [shouldFocus]);
//   useEffect(() => {
//     console.log("should mem")
//     if (shouldFocusmember==true && inputRefMemId.current) {
//         setShouldFocus(false)
//         inputRefMemId.current.focus();
//       }
//   }, [shouldFocusmember]);
  let total = 0
  const grandTotal=()=>{
      if(finalMembers.length>0){
          total = 0
          finalMembers.map((data)=>{
        total += parseInt(data.amount)
        setTotal(total)  
      })
      return total}
  }
  let balance=0
  const Balance=()=>{ 
      balance = handleFormattedAmount(bulkAmount) - total
      setBL(balance)
      return balance
  }

  const footGroup=(
    <ColumnGroup>
    <Row>
        <Column footer={"Total : "} colSpan={6} footerStyle={{ textAlign: 'right' }} />
        <Column footer={grandTotal}  footerStyle={{ textAlign: 'left' , fontWeight:'normal'}} />
        <Column footer={"Balance : "} footerStyle={{ textAlign: 'right' }} />
        <Column footer={Balance}  footerStyle={{ textAlign: 'left' , fontWeight:'normal'}} />
    </Row>
</ColumnGroup>)
const estatefilter=(data)=>{
    console.log(data)
    const estatenameAdded = data.map(bulk => {
        // Find the corresponding object in array2
        const matchedItem = estateArray.find(estate => estate.estateid === bulk.estateid);
        // Add the 'name' property to the object in array1 if a match is found
        console.log(matchedItem)
        return {
            ...bulk,
            estatenamefull: matchedItem ? matchedItem.estatename : null
        };
    });
    return estatenameAdded
}
const handleConfirmDelete = async() => {
    // Handle deletion logic here
    let formattedDate
    if(typeof(date)!=="string"){
    //     let dateObj = new Date(date[0])
    // const year = dateObj.getFullYear();
    // const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    // const day = String(dateObj.getDate()).padStart(2, '0');
    // const hours = String(dateObj.getHours()).padStart(2, '0');
    // const minutes = String(dateObj.getMinutes()).padStart(2, '0');
    // const seconds = String(dateObj.getSeconds()).padStart(2, '0');
    // formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`
    formattedDate = date?.format("YYYY-MM-DDTHH:mm:ss[Z]")
}
    const rowData = rowDataToDelete
    rowData['chequedate'] = typeof(date)!=="string"?formattedDate:date
    rowData['bankdeposit'] = coopbank??null
    rowData['chequeno'] = chequeNo??null
    rowData['createdby'] = localStorage.getItem('username')
    const data = rowIndexToDelete
    if(rowData.receiptid){
        let payload = {
         transdetailid: rowData.receiptid,
         transmasterid: rowData.transmasterid,
         memberid: rowData.memberid,
         trans:rowData
        }
        //console.log(payload)
        const res = await CustomAxios.post(`oasis/remove_deposit/`,payload)
        //console.log(res)
        if(res.data == 'deleted successfully'){
            setDeletedStatus(true)
            const updatedMembers = [...finalMembers];
        updatedMembers.splice(data.rowIndex,1)
        setTotal(parseInt(Total)-parseInt(rowData.transamount))
        setfinalMembers(updatedMembers);
        }
        
    }
    else{
        const updatedMembers = [...finalMembers];
        updatedMembers.splice(data.rowIndex,1)
        setTotal(parseInt(Total)-parseInt(rowData.transamount))
        setfinalMembers(updatedMembers);
    }
    setDeletemodal(false);
    // Call your delete function here
  };
  useEffect(()=>{
     if(deleteStatus==true){
        setTimeout(() => {
            setDeletedStatus(false)
        }, 5000);
     }
  },[deleteStatus])
//   useEffect(()=>{
//     if(newReceipt==true){
//        setItems(null)
//     }
//  },[newReceipt])
//    const handleDropdownChange = (e) => {
//     const filtered = estateArray.filter((data) => e.value == data.estatename)
//     filtered.length>0 && setCurrentState(filtered[0].state+"-"+filtered[0].add4)
//     if(filtered[0]?.manager!==null){
//         setManager(filtered[0]?.manager.trim())
//     }else{
//         setManager("")
//     }
//     setEstate(e.value)
//   };
    
    const handleDropdownChange = (event, value) => {
        const filtered = estateArray.filter((data) => value === data.estatename);
        if (filtered.length > 0) {
          setCurrentState(filtered[0].state + "-" + filtered[0].add4);
          if (filtered[0]?.manager !== null) {
            setManager(filtered[0]?.manager.trim());
          } else {
            setManager("");
          }
          setEstate(value);
        }
  };
//   const [virtualCars, setVirtualCars] = useState([]);
//   const [lazyLoading, setLazyLoading] = useState(false);
//   const [first, setFirst] = useState(0);
//   const [rows, setRows] = useState(10);

//   const loadCarsLazy = (event) => {
//       if (!lazyLoading) {
//           setLazyLoading(true);
//           setFirst(event.first);
//           setRows(event.rows);

//           setTimeout(() => {
//             console.log(finalMembers)
//             if(finalMembers.length>0){
//                 const loadedCars = finalMembers.slice(event.first, event.first + event.rows);
//               setVirtualCars(loadedCars);
//               setLazyLoading(false);
//             }
              
//           }, 100); 
//       }
//   };

//   const loadingTemplate = () => {
//       return (
//           <div style={{ height: '17px', flexGrow: '1', overflow: 'hidden' }}>
//                <Skeleton width={options.cellEven ? (options.field === 'year' ? '30%' : '40%') : '60%'} height="1.5rem" />
//           </div>
//       );
//   };
//   console.log("finalMembers",finalMembers) 
//   console.log("finalmembersLastTrans",finalmembersLastTrans) 
//   console.log("bulkTrans",bulkTrans) 
// console.log("pdflist",pdflist) 
const [selectedCity, setSelectedCity] = useState(null);
const cities = ['New York', 'Rome', 'London', 'Istanbul', 'Paris'];
console.log("finalmembersSearched",coopbank,selectedCity) 

const handleDateChange = (e, error) => {
    
    if (error.validationError== "disableFuture")
        setIsFutureDateError(true)
    else if(isFutureDateError)
        setIsFutureDateError(false)
    
    e && setDate(e)
  }

// console.log("==========================",coopbank!=="",bank!=="",chequeNo!=="",bulkAmount!=="",date!==null,estate!=="",currentState!=="",manager!=="",narration!=="",receiptModal,"----------",finalMembers,Total) 
    return (
        <Box sx={{backgroundColor:isMobile&&"#f2f6fc",height:isMobile?"100vh":"calc(100vh - 72px)",width:"100%",position:"relative",overflow:"auto", marginTop:"60px"}}>
        { newReceipt==false?
        <Box>
            <Box sx={{ display:"flex",flexDirection:"column",gap:"20px",height: {sm:'initial',padding: "0px 20px 0px 20px"},width:"100%" }}>
                <Box sx={{top:"72px",left:"304px",padding:"8px 0px 8px 0px",display:"flex",justifyContent:"space-between",borderBottom:"1px solid #CBD5E1",alignItems:"center",height:"84px"}}>
                    <Box sx={{maxWidth:"200px",height:"48px",padding:"8px 0px 8px 0px",gap:"12px",display:"flex",alignItems:"center"}}>
                        <Box sx={{width:"32px",height:"32px"}}>
                                    <ReceiptOutlinedIcon sx={{width:"32px",height:"32px",color:theme.palette.primary.main}}/> 
                        </Box>
                        <Typography sx={{fontFamily:"inter",fontSize:"20px",fontWeight:"600",lineHeight:"28px",color:theme.palette.primary.main}}>Receipts</Typography>
                    </Box>

                        {/* <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                            <Box className="p-input-icon-left" sx={{display:"flex",width:"100%"}}>
                                <i className="pi pi-search" style={{color:"#64748B",zIndex:"2"}}/>
                                <AutoComplete value={searchGlobalBulkNo} placeholder="Bulk # / Receipt # /Estate Name"  suggestions={estateLists} completeMethod={filterEstate} onKeyUp={(e)=>handleEnter(e)} onChange={(e) => setSearchGlobalBulk(e.target.value)} forceSelection style={{height:"48px",width:"400px",fontSize:"16px",color:"#64748B"}}/>
                                <Box sx={{display:"flex",alignItems:"center"}}>
                                </Box>
                            </Box>
                        </Box> */}

                        <MuiSearchBar
                            handleEnter={handleEnter}
                            search={searchGlobalBulkNo}
                            setSearch={setSearchGlobalBulk}
                            placeholder="Bulk # / Receipt # /Estate Name"
                        />

                        <Box sx={{ display: "flex", gap: "20px", width: "330px", justifyContent: "flex-end" }}>

                        <MuiButton
                                variant="contained"
                                onClick={(e) => {
                                    clearFormMaster(e)
                                    clearform(e)
                                    setNewReceipt(true)
                                }} 
                                value="Add Receipts"
                                icon={<ReceiptOutlinedIcon />}
                            />

                            {/* <Button 
                            onClick={(e) => {
                                clearFormMaster(e)
                                clearform(e)
                                setNewReceipt(true)
                            }} 
                            variant="contained" sx={{ width: "201px", height: "48px", borderRadius: "4px", padding: "12px", display: "flex", gap: "8px", background: "#288d2c", textTransform: "none" }}><ReceiptOutlinedIcon sx={{ width: "24px", height: "24px", color: "#FFFFF" }} /> <Typography sx={{ color: "#FFFFFF", fontSize: "16px", fontWeight: "400", lineHeight: "24px", fontFamily: "inter" }}>Add Receipts</Typography>
                            </Button> */}
                        </Box>

            </Box>
          </Box>
          <Box sx={{padding:"30px 30px 0px 30px",display:"flex",flexDirection:"column",gap:"24px",alignItems:"center"}}>
               
               <Box sx={{width:"100%"}}>
                   {/* <DataTable value={searchGlobalBulkNo!==""?finalmembersSearched:finalmembersLastTrans }  rowGroupMode="rowspan" groupRowsBy="transmasterid" rows={7} paginatorLeft={paginatorLeft} paginatorRight={paginatorRight} dataKey="receiptid" style={{ backgroundColor: "#F1F5F9",position:"relative" }} scrollable   removableSort loading={isLoading} paginator 
                      emptyMessage="No Bulks Found." >
                       <Column field="transmasterid" header="Bulk #" body={bodyTemplate} style={{ minWidth: "250px",color:"#101010",fontFamily:"inter",fontSize:"14px",fontWeight:"500",lineHeight:"20px" }}></Column>
                      {bulkColumnVisible.filter(col => col.visible).map((col,ind) => <Column className="custom-column-style" style={{ position:"relative",minWidth: "200px",color:"#101010",fontFamily:"inter",fontSize:"14px",fontWeight:"500",lineHeight:"20px" }} key={ind} field={col.field} filterElement={col.filterElement} header={col.header} sortable={renderHeader(col)} body={col.body} filter={col.filter} alignFrozen="left" dataType={col.datatype} filterField={col.filterField} filterPlaceholder={col.filterPlaceholder} frozen={col.frozen} />)}
                      <Column header ='Action' style={{ minWidth: "250px",color:"#101010",fontFamily:"inter",fontSize:"14px",fontWeight:"500",lineHeight:"20px" }} body={PrintData} />
                  </DataTable>
                      <Column header ='Action' style={{ minWidth: "250px",color:"#101010",fontFamily:"inter",fontSize:"14px",fontWeight:"500",lineHeight:"20px" }} body={PrintData} /> */}
                            <DataTable value={searchGlobalBulkNo !== "" && finalmembersSearched.length > 0 ? finalmembersSearched : bulkList} rows={7} dataKey="sn" style={{ backgroundColor: "#F1F5F9" }} paginatorLeft={paginatorLeft} paginatorRight={paginatorRight} scrollable columnfinalMembersizeMode="expand" removableSort finalMembersizableColumns paginator filters={filters} filterDisplay="row"
                                emptyMessage="No Bulks found." >
                                <Column header="SN" headerStyle={{ width: '50px' }} body={(data, options) => options.rowIndex + 1}></Column>
                                {bulkColumnVisible.filter(col => col.visible).map((col, ind) => <Column className="custom-column-style" style={col.style} key={ind} field={col.field} filterElement={col.filterElement} header={col.header} sortable={col.sortable} body={col.body} filter={col.filter} alignFrozen="left" dataType={col.datatype} filterField={col.filterField} filterPlaceholder={col.filterPlaceholder} frozen={col.frozen} />)}
                            </DataTable>
                      {/* <Row>HI</Row> */}
              </Box>
              <Dialog visible={printModal} style={{maxWidth:"553px",width:"100%",boxShadow:"0px 0px 34px 0px #64748B80"}} draggable={false} position="center" modal={false} closable={false} resizable={false}>
                <Box sx={{padding:"30px",display:"flex",flexDirection:"column",gap:"24px"}}>
                     <Typography sx={{fontFamily:"inter",fontSize:"18px",fontWeight:"600",lineHeight:"28px",color:"#000000"}}>{bulkHeading}</Typography>
                     <Box sx={{display:"flex",flexDirection:"column",gap:"8px"}}>
                        {/* <Typography sx={{fontFamily:"inter",fontSize:"16px",fontWeight:"400",lineHeight:"24px",color:"#101010"}}>Bulk Number  <Typography component="span" sx={{color:"red"}}>*</Typography> </Typography>
                        <InputText
                            value={searchBulkNo}
                            placeholder="Bulk Number"
                            onChange={(e)=>setSearchBulk(e.target.value)}
                            style={{ display:"flex",gap:"8px", width: "289px", height:"36px", borderRadius: "4px" }}
                        /> */}
                        <MuiInput
                            label='Bulk Number'
                            value={searchBulkNo}
                            onChange={(e)=>setSearchBulk(e.target.value)}
                            sx={{ display:"flex",gap:"8px", width: "289px", height:"36px", borderRadius: "4px" }}
                        />
                     </Box>
                </Box>
                     <Box sx={{display:"flex",justifyContent:"center",height:"96px",padding:"24px 30px",background:"#F1F5F9"}}>
                            <Box sx={{width:"406px",display:"flex",gap:"24px"}}>
                                <Button onClick={()=>{
                                    setBulkHeading("")
                                    setPrintModal(false)}} variant="text"  sx={{maxWidth:"201px",width:"100%",height:"48px",borderRadius:"4px",padding:"12px",display:"flex",gap:"8px",background:"transparent", textTransform: "none"}}> <ClearIcon sx={{color:"#64748B"}}/><Typography sx={{color:"rgb(100, 116, 139)",fontSize:"16px",fontWeight:"400",lineHeight:"24px",fontFamily:"inter"}}>Cancel</Typography></Button>
                                <button type="submit" variant="contained" style={{border:"none",color:"#FFFFFF", textTransform:"none",width:"204px",height:"48px",borderRadius:"4px",padding:"12px",background:"#288d2c", cursor: "pointer" }} onClick={(e) => handleOptions(e)}>
                                        <Typography sx={{ alignSelf: "stretch", width: "100%", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px",color:"#FFFFFF"}}>{printModalButton}</Typography>
                                </button>
                            </Box>
                     </Box>
              </Dialog>
          </Box>
            </Box>:
                 <form ref={formRef} onSubmit={(e)=>{
                    setReceiptModal(true)
                    handlesubmit(e)}} >
            <Box sx={{position:"relative"}}>
               {success==true&& <Box sx={{maxWidth:"580px",width:"100%",height:"84px",border:"2px solid #288d2c",background:"#DCFCE7",borderRadius:"4px",display:"flex",gap:"16px",justifyContent:"center",alignItems:"center",position:"absolute",top:"34px",left:"32%",zIndex:"2"}}>
                        <IconButton><DoneIcon onClick={()=>setSucces(false)} sx={{width:"24px",height:"24px"}}/></IconButton>
                                <Box>
                                    <Typography sx={{ alignSelf: "stretch", width: "100%", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "700", lineHeight: "24px",color:"#16A34A"}}>{action=="edit"?"Bulk updated successfully":"New Payment added successfully"}</Typography>
                                    <Typography sx={{ alignSelf: "stretch", width: "100%", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px",color:"#16A34A"}}>{action=="edit"?"":"Bulk Number and RN has been generated for the Payment."}</Typography>
                                </Box>
                        <IconButton><ClearIcon onClick={()=>setSucces(false)} sx={{width:"24px",height:"24px"}}/></IconButton>
                </Box>}


                {deleteStatus==true&& <Box sx={{maxWidth:"580px",width:"100%",height:"84px",border:"2px solid #ce0a0a",background:"#ff080891",borderRadius:"4px",display:"flex",gap:"16px",justifyContent:"center",alignItems:"center",position:"absolute",top:"34px",left:"32%",zIndex:"2"}}>
                        <IconButton><DoneIcon onClick={()=>setDeletedStatus(false)} sx={{width:"24px",height:"24px",color:"#FFF"}}/></IconButton>
                                <Box>
                                    <Typography sx={{ alignSelf: "stretch", width: "100%", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "700", lineHeight: "24px",color:"#fff"}}>Transaction Deleted Successfully</Typography>
                                </Box>
                        <IconButton><ClearIcon onClick={()=>setDeletedStatus(false)} sx={{width:"24px",height:"24px",color:"#FFF"}}/></IconButton>
                </Box>}
            <Box ref={containerRef}  sx={{height:"calc(100vh - 148px)",overflow:"auto"}}>
                <Box sx={{ display:"flex",flexDirection:"column",gap:"20px",height: {sm:'initial',padding: "0px 20px 0px 20px"},width:"100%" }}>
                                    <Box sx={{top:"72px",left:"304px",padding:"8px 0px 8px 0px",display:"flex",borderBottom:"1px solid #CBD5E1",alignItems:"center",height:"84px",justifyContent:"space-between"}}>
                                    <Box sx={{maxWidth:"200px",height:"48px",padding:"8px 0px 8px 0px",gap:"12px",display:"flex",alignItems:"center"}}>
                            <Box sx={{width:"32px",height:"32px"}}>
                                    <ReceiptOutlinedIcon sx={{width:"32px",height:"32px",color:"#288d2c"}}/> 
                             </Box>
                            <Typography sx={{fontFamily:"inter",fontSize:"20px",fontWeight:"600",lineHeight:"28px",color:"#101010"}}>Receipts</Typography>
                        </Box>
                        <Box sx={{width:action=="edit"||action=="dup"?"609px":"406px",display:"flex",gap:"24px"}}>
                                    {/* <Button onClick={(e)=>clearFormMaster(e)} variant="text"  sx={{maxWidth:"201px",width:"100%",height:"48px",borderRadius:"4px",padding:"12px",display:"flex",gap:"8px",background:"transparent", textTransform: "none"}}> <ClearIcon sx={{color:"#64748B"}}/><Typography sx={{color:"rgb(100, 116, 139)",fontSize:"16px",fontWeight:"400",lineHeight:"24px",fontFamily:"inter"}}>Clear</Typography></Button> */}
                                    <MuiButton
                                        variant="text"
                                        onClick={(e)=>clearFormMaster(e)}
                                        value="Clear"
                                        icon={<ClearIcon/>}
                                        iconsx={{
                                            color: "#64748B"
                                        }}
                                    />
                                    {/* <button type="submit" variant="contained" disabled={response.length==0&&finalMembers.length !==0 && Total==bulkAmount?false:true} style={{border:"none",color:"#FFFFFF", textTransform:"none",width:"204px",height:"48px",borderRadius:"4px",padding:"12px",background:"#288d2c", cursor: response.length==0&&finalMembers.length !==0 && Total==bulkAmount? "pointer" : "not-allowed" }} onClick={(event) => handleSave(event)}>
                                    <Typography sx={{ alignSelf: "stretch", width: "100%", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px",color:"#FFFFFF"}}>Submit</Typography>
                                  </button> */}
                                   <MuiButton
                                        type="submit" 
                                        variant="contained"
                                        disabled={response.length==0&&finalMembers.length !==0 && Total==handleFormattedAmount(bulkAmount)?false:true}
                                        btnsx={{ cursor: response.length==0&&finalMembers.length !==0 && Total==handleFormattedAmount(bulkAmount)? "pointer" : "not-allowed"} }
                                        onClick={(event) => handleSave(event)}
                                        value="Submit"
                                    />
                                    {(action=="edit"||action=="dup")&&
                                        // <Button 
                                        // onClick={(e)=>setEditDetails(!editdetails) } 
                                        // variant="outlined"  
                                        // sx={{maxWidth:"201px",width:"100%",height:"48px",borderRadius:"4px",padding:"12px",display:"flex",gap:"8px",color:"#288d2c", textTransform: "none"}}> 
                                        // <Typography sx={{color:editdetails==false?"#288d2c":"#101010",fontSize:"16px",fontWeight:"400",lineHeight:"24px",fontFamily:"inter",display:"flex",gap:"12px"}}>
                                        //     <EditNoteIcon sx={{color:"#288d2c"}}/>
                                        //     Edit Details
                                        // </Typography>
                                        // </Button>
                                        <MuiButton
                                            onClick={(e)=>setEditDetails(!editdetails) } 
                                            variant="outlined" 
                                            value="Edit Details"
                                            icon={<EditNoteIcon/>}
                                            iconsx={{
                                                color: "#64748B"
                                            }}
                                        />
                                        }
                                    </Box>
                        
                            </Box>
                 </Box>
                 <Box sx={{padding:"30px 30px 75px 30px",display:"flex",flexDirection:"column",gap:"24px"}}>
                            <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                                        <Box sx={{maxWidth:"1228px",width:"100%",display:"flex",justifyContent:"space-between",marginRight:"30px",alignItems:"center"}}>
                                            <Box sx={{maxWidth:"200px",height:"48px",padding:"8px 0px 8px 0px",gap:"12px",display:"flex",alignItems:"center"}}>
                                            <Box sx={{width:"32px",height:"32px"}}>
                                                    <ReceiptOutlinedIcon sx={{width:"32px",height:"32px",color:"#288d2c"}}/> 
                                            </Box>
                                        {/* <Typography sx={{fontFamily:"inter",fontSize:"18px",fontWeight:"600",lineHeight:"28px",color:"#101010"}}>Create Receipts</Typography> */}
                                        </Box>
                                          {/* <InputText
                                            value={payload.bulkNo}
                                            placeholder="Bulk Number"
                                            style={{ display:"flex",gap:"8px", width: "289px", height:"36px", borderRadius: "4px" }}
                                        /> */}
                                        <MuiInput
                                            label='Bulk Number'
                                            value={payload.bulkNo}
                                            style={{ display:"flex",gap:"8px", width: "289px", height:"36px", borderRadius: "4px" }}
                                        />
                                </Box>
                              
                            <IconButton onClick={(e)=>{
                                clearform(e)
                                clearFormMaster(e)
                                setNewReceipt(false)}} > <ClearIcon /></IconButton>
                            </Box>
                            <Box sx={{display:"flex",gap:"24px",flexDirection:"column",width:"100%",pointerEvents:editdetails==true?"none":"auto"}}>
                                  <Box>
                                      <Typography sx={{fontFamily:"inter",fontSize:"18px",fontWeight:"500",lineHeight:"28px",color:"#64748B"}}>Deposited Details</Typography>
                                  </Box>
                                  <Box sx={{display:"flex",gap:"24px",width:"100%",flexWrap:"wrap"}}>
                                        <Box sx={{display:"flex",gap:"8px",flexDirection:"column",maxWidth:"289px",width:"100%"}}>
                                            {/* <Typography sx={{ alignSelf: "stretch", width: "200px", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px",color:"#101010"}}>Deposited bank <Typography component="span" sx={{color:"red"}}>*</Typography> </Typography> */}
                                             {/* <Dropdown
                                                required
                                                value={coopbank}
                                                onChange={(e) => setCoopbank(e.target.value)}
                                                style={{ width: "289px", height: "36px", display: "flex", alignItems: "center" }}
                                                options={coopBankList.sort()}
                                                placeholder="All"
                                                filter
                                            />  */}
                                            <MuiAutocomplete
                                                label='Deposited bank'
                                                required={true}
                                                value={coopbank}
                                                onChange={(event, newValue) => setCoopbank(newValue)}
                                                autoCompleteStyle={{ width: "289px", height: "36px", display: "flex", alignItems: "center" }}
                                                options={coopBankList.sort()}
                                                />
                                             
                                        </Box>
                                        <Box sx={{display:"flex",gap:"8px",flexDirection:"column",maxWidth:"289px",width:"100%"}}>
                                            {/* <Typography sx={{ alignSelf: "stretch", width: "200px", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px",color:"#101010"}}>Bank <Typography component="span" sx={{color:"red"}}>*</Typography> </Typography>
                                            <Dropdown
                                                required={true}
                                                value={bank}
                                                onChange={(e) => setBank(e.target.value)}
                                                style={{ width: "289px", height: "36px", display: "flex", alignItems: "center" }}
                                                options={bankList.sort()}
                                                placeholder="All"
                                                filter
                                            /> */}
                                            <MuiAutocomplete
                                                required={true}
                                                value={bank}
                                                onChange={(event, newValue) => setBank(newValue)}
                                                autoCompleteStyle={{ width: "289px", height: "36px", display: "flex", alignItems: "center" }}
                                                options={bankList.sort()}
                                                label="Bank " 
                                            />
                                        </Box>
                                        <Box sx={{display:"flex",gap:"8px",flexDirection:"column",maxWidth:"289px",width:"100%"}}>
                                                {/* <Typography sx={{ alignSelf: "stretch", width: "200px", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px",color:"#101010"}}>Cheque No <Typography component="span" sx={{color:"red"}}>*</Typography> </Typography>
                                                <InputText
                                                    required
                                                    value={chequeNo}
                                                    placeholder="Enter Cheque #"
                                                    onChange={(e) => setChequeNo(e.target.value)}
                                                    style={{ display:"flex",gap:"8px", width: "289px", height:"36px", borderRadius: "4px" }}
                                                    /> */}
                                            <MuiInput
                                                    label='Cheque No'
                                                    required
                                                    value={chequeNo}
                                                    // placeholder="Enter Cheque #"
                                                    onChange={(e) => setChequeNo(e.target.value)}
                                                    style={{ display:"flex",gap:"8px", width: "289px", height:"36px", borderRadius: "4px" }}
                                                    />
                                        </Box>
                                        <Box sx={{display:"flex",gap:"8px",flexDirection:"column",maxWidth:"289px",width:"100%"}}>
                                                 {/* <Typography sx={{ alignSelf: "stretch", width: "200px", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px",color:"#101010"}}>Bulk Amount <Typography component="span" sx={{color:"red"}}>*</Typography> </Typography>
                                                <InputText
                                                    required
                                                    value={bulkAmount}
                                                    placeholder="Enter Amount"
                                                    onChange={(e) => setBulkAmount(e.target.value)}
                                                    style={{ display:"flex",gap:"8px", width: "289px", height:"36px", borderRadius: "4px" }}
                                                    />  */}
                                            <MuiInputAmount
                                                inputKey="amount1_receipts"
                                                ref={inputRefBulkAmt}
                                                amount={bulkAmount}
                                                setAmount={setBulkAmount}
                                                label='Bulk Amount'
                                                required
                                                placeholder="Enter Amount"
                                                style={{ display:"flex",gap:"8px", width: "289px", height:"36px", borderRadius: "4px" }}
                                            />
                                        </Box>
                                </Box>

                                <Box sx={{display:"flex",gap:"24px",width:"100%",flexWrap:"wrap"}}>
                                        <Box sx={{display:"flex",gap:"8px",flexDirection:"column",maxWidth:"289px",width:"100%"}}>
                                                {/*<Typography sx={{ alignSelf: "stretch", width: "100%", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px",color:"#101010"}}>Deposit / Cheque Cleared date <Typography component="span" sx={{color:"red"}}>*</Typography> </Typography>
                                                 <Calendar required value={date?new Date(date[0]):null} dateFormat="dd-mm-yy" onChange={(e) => {setDate([e.value])}}  style={{ width: "289px", height: "36px", display: "flex", alignItems: "center" }} placeholder="dd-mm-yyyy"/>          */}
                                                
                                                <MuiDatePicker
                                                //  required={true}
                                                    isSubmitted={isSubmitted}
                                                    validationErrMsg={errors.depositDate}
                                                //  className={errors.depositDate ? "p-invalid" : ""}
                                                    name="depositDate"
                                                    label="Deposit / Cheque Cleared date"
                                                    value={date}
                                                    onChange={handleDateChange}
                                                    format="DD-MM-YYYY"
                                                />
                                        </Box>
                                        <Box sx={{display:"flex",gap:"8px",flexDirection:"column",maxWidth:"289px",width:"100%"}}>
                                           {/* <Typography sx={{ alignSelf: "stretch", width: "200px", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px",color:"#101010"}}>Estate Name <Typography component="span" sx={{color:"red"}}>*</Typography> </Typography> */}
                                            {/* <Dropdown
                                                required
                                                value={estate}
                                                onChange={(e) => handleDropdownChange(e)}
                                                style={{ width: "289px", height: "36px", display: "flex", alignItems: "center" }}
                                                options={estateList.sort()}
                                                placeholder="All"
                                                filter
                                            />  */}
                                            <MuiAutocomplete
                                                label='Estate Name'
                                                required
                                                value={estate}
                                                onChange={handleDropdownChange}
                                                options={estateList.sort()}
                                                placeholder="All"
                                                filter
                                                autoCompleteStyle={{ width: "289px", height: "36px", display: "flex", alignItems: "center" }}
                                                />
                                        </Box>
                                        <Box sx={{display:"flex",gap:"8px",flexDirection:"column",maxWidth:"289px",width:"100%"}}>
                                                {/* <Typography sx={{ alignSelf: "stretch", width: "200px", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px",color:"#101010"}}>State <Typography component="span" sx={{color:"red"}}>*</Typography> </Typography>
                                                <InputText
                                                    value={currentState}
                                                    placeholder="State"
                                                    style={{ display:"flex",gap:"8px", width: "289px", height:"36px", borderRadius: "4px" }}
                                                    /> */}
                                            <MuiInput
                                                    label="State"
                                                    value={currentState}
                                                    placeholder="State"
                                                    style={{ display:"flex",gap:"8px", width: "289px", height:"36px", borderRadius: "4px" }}
                                                    />
                                        </Box>
                                        <Box sx={{display:"flex",gap:"8px",flexDirection:"column",maxWidth:"289px",width:"100%"}}>
                                                {/* <Typography sx={{ alignSelf: "stretch", width: "200px", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px",color:"#101010"}}>Manager <Typography component="span" sx={{color:"red"}}>*</Typography> </Typography>
                                                <InputText
                                                    value={manager?.trim()}
                                                    placeholder="Manager Name"
                                                    onChange={(e)=>setManager(e.target.value)}
                                                    style={{ display:"flex",gap:"8px", width: "289px", height:"36px", borderRadius: "4px" }}
                                                    /> */}
                                            <MuiInput
                                                label='Manager Name'
                                                required
                                                value={manager?.trim()}
                                                    onChange={(e)=>setManager(e.target.value)}
                                                    style={{ display:"flex",gap:"8px", width: "289px", height:"36px", borderRadius: "4px" }}
                                                    />
                                        </Box>
                                </Box>

                                <Box sx={{display:"flex",gap:"24px",width:"100%",flexWrap:"wrap"}}>
                                        <Box sx={{display:"flex",gap:"8px",flexDirection:"column",maxWidth:"602px",width:"100%"}}>
                                                {/* <Typography sx={{ alignSelf: "stretch", width: "100%", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px",color:"#101010"}}>Notes / Narration <Typography component="span" sx={{color:"red"}}>*</Typography> </Typography>
                                                <InputText
                                                    required
                                                    value={narration}
                                                    placeholder="Enter Narration"
                                                    onChange={(e) => setNarration(e.target.value)}
                                                    style={{ display:"flex",gap:"8px", width: "100%", height:"36px", borderRadius: "4px" }}
                                                    /> */}
                                            <MuiInput
                                                    label='Notes / Narration '
                                                    required
                                                    value={narration}
                                                    placeholder="Enter Narration"
                                                    onChange={(e) => setNarration(e.target.value)}
                                                    style={{ display:"flex",gap:"8px", width: "100%", height:"36px", borderRadius: "4px" }}
                                                    />
                                        </Box>
                                </Box>

                                {/* <Box sx={{display:"flex",gap:"24px"}}>
                                       <button type="submit" disabled={coopbank!==""&&bank!==""&&bulkAmount!==""&&chequeNo!==""&&date!==null&&estate!==""&&narration!==""?false:true} style={{display:"flex",maxWidth:"159px",width:"100%",height:"36px",background:"#475569",border:"none",padding:"8px 12px",borderRadius:"4px",gap:"8px",justifyContent:"center",alignItems:"center",cursor:coopbank!==""&&bank!==""&&bulkAmount!==""&&chequeNo!==""&&date!==null&&estate!==""&&narration!==""?"pointer":"not-allowed",border:"none",outline:"none"}} ><AddIcon sx={{width:"24px",height:"24px",color:"#FFFFFF"}}/> <Typography sx={{color:"#FFFFFF",fontSize:"16px",fontWeight:"400",lineHeight:"24px",fontFamily:"inter"}}>ADD LIST</Typography></button>
                                </Box> */}
                                <Box >
                        <form ref={formRefdeposit} style={{pointerEvents:receiptModal==true?"auto":"none"}}>
                                <Box sx={{display:"flex",flexDirection:"column",gap:"12px"}}>
                                     <Box sx={{display:"flex",gap:"20px",width:"100%",flexWrap:"wrap",alignItems:"flex-end"}}>
                                        <Box className="autocompletememid" sx={{display:"flex",gap:"8px",flexDirection:"column"}}>
                                                <Typography sx={{ alignSelf: "stretch", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px",color:"#101010"}}>Member Number<Typography component="span" sx={{color:"red"}}>*</Typography> </Typography>
                                                 <AutoComplete ref={inputRefMemId} value={memberid} placeholder="Enter Member Number" disabled={Total>=payload.transamount?true:false} suggestions={items} completeMethod={filterOptions} onKeyUp={(e)=>handleClickIdNameNric(e, inputRefMemId)} onChange={(e) => setMemberId(e.target.value)}  forceSelection style={{ display:"flex",gap:"8px", height:"36px", borderRadius: "4px" }}/>
                                        </Box>
                                        <Box className="autocompletememname" sx={{display:"flex",gap:"8px",flexDirection:"column"}}>
                                                <Typography sx={{ alignSelf: "stretch", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px",color:"#101010"}}>Member Name <Typography component="span" sx={{color:"red"}}>*</Typography> </Typography>
                                                <AutoComplete ref={inputRefMemName} value={memberName} placeholder="Enter Member Name" disabled={Total>=payload.transamount?true:false} suggestions={items} completeMethod={filterOptionsName} onKeyUp={(e)=>handleClickIdNameNric(e, inputRefMemName)} onChange={(e) => setMemberName(e.target.value)} forceSelection style={{ display:"flex",gap:"8px", height:"36px", borderRadius: "4px" }}/>
                                        </Box>
                                        <Box className="autocompletenicno" sx={{display:"flex",gap:"8px",flexDirection:"column"}}>
                                                <Typography sx={{ alignSelf: "stretch", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px",color:"#101010"}}>New Ic No <Typography component="span" sx={{color:"red"}}>*</Typography> </Typography>
                                                <AutoComplete ref={inputRefNewNric} value={newNric} placeholder="Enter Nric new" disabled={Total>=payload.transamount?true:false} suggestions={items} completeMethod={filterOptionsNric} onKeyUp={(e)=>handleClickIdNameNric(e, inputRefNewNric)} onChange={(e) => setNewNric(e.target.value)} forceSelection style={{ display:"flex",gap:"8px", height:"36px", borderRadius: "4px" }}/>
                                        </Box>
                                        <Box sx={{display:"flex",gap:"8px",flexDirection:"column"}}>
                                                {/*<Typography sx={{ alignSelf: "stretch", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px",color:"#101010"}}>Old Ic No <Typography component="span" sx={{color:"red"}}>*</Typography> </Typography>
                                                <InputText
                                                    value={currentMember.length>0?currentMember[0].oldicno:OldNric}
                                                    disabled={Total>=payload.transamount?true:false}
                                                    placeholder="Nric Old"
                                                    onChange={(e) => setOldNric(e.target.value)}
                                                    style={{ display:"flex",gap:"8px", width: "150px", height:"36px", borderRadius: "4px" }}
                                                    /> */}
                                                <MuiInput
                                                    label='Old Ic No'
                                                    required
                                                    value={currentMember.length>0?currentMember[0].oldicno:OldNric}
                                                    disabled={Total>=payload.transamount?true:false}
                                                    onChange={(e) => setOldNric(e.target.value)}
                                                    style={{ display:"flex",gap:"8px", width: "150px", height:"36px", borderRadius: "4px" }}
                                                    />
                                        </Box>

                                        <Box sx={{display:"flex",gap:"8px",flexDirection:"column"}}>
                                                <Typography sx={{ alignSelf: "stretch", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px",color:"#101010"}}>Amount <Typography component="span" sx={{color:"red"}}>*</Typography> </Typography>
                                                {/* <InputText
                                                     ref={inputRef}
                                                     value={amount}
                                                    //  type="number"
                                                     disabled={Total>=payload.transamount?true:false}
                                                     onKeyDown={handleKeyDown}
                                                     onChange={(e) => handleAmountChange(e)}
                                                    style={{ display:"flex",gap:"8px", width: "150px", height:"36px", borderRadius: "4px" }}
                                                    /> */}
                                                <MuiInputAmount
                                                    inputKey="amount2_receipts"
                                                    amount={amount}
                                                    setAmount={setAmount}
                                                    // inputRef={inputRef}
                                                    ref={inputRef}
                                                    required
                                                    disabled={Total>=payload.transamount?true:false}
                                                    currentMember={currentMember}
                                                    memberShareCheck={memberShareCheck}
                                                    setTransType={setTransType}
                                                    style={{ display:"flex",gap:"8px", width: "150px", height:"36px", borderRadius: "4px" }}
                                                />
                                                    {/* <MuiInput
                                                     label='Amount'
                                                     required
                                                     ref={inputRef}
                                                     value={amount}
                                                    //  type="number"
                                                     disabled={Total>=payload.transamount?true:false}
                                                     onKeyDown={handleKeyDown}
                                                     onChange={(e) => handleAmountChange(e)}
                                                    style={{ display:"flex",gap:"8px", width: "150px", height:"36px", borderRadius: "4px" }}
                                                    /> */}
                                        </Box> 
                                        <Box sx={{display:"flex",gap:"8px",flexDirection:"column"}} >
                                        { <>
                                            <Typography sx={{ alignSelf: "stretch", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px",color:"#101010"}}>Transaction Type<Typography component="span" sx={{color:"red"}}>*</Typography> </Typography>
                                        {currentMember.length>0&&(((currentMember[0].loanbalance==0&&currentMember[0].sharebalance>=1200)||(handleFormattedAmount(amount)>1200&&currentMember[0].loanbalance==0))||memberShareCheck.length>0&&(parseInt(memberShareCheck[0].updatedShareBal)+parseFloat(handleFormattedAmount(amount))>1200&&currentMember[0].loanbalance==0))?<InputText
                                        value="Subscription"
                                        sx={{ display:"flex",gap:"8px", width: "150px", height:"36px", borderRadius: "4px" }}
                                    />:
                                    <Select onChange={(e) => setTransType(e.target.value)}
                                    displayEmpty
                                    disabled={Total>=payload.transamount?true:false}
                                    value={transtype}
                                    IconComponent={expandmore}
                                    style={{  width: "150px", height: "36px", display: "flex", alignItems: "center",background:"#FFFFFF"}}
                                    >
                                    <MenuItem value="" disabled sx={{fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px",color:"#101010"}}>Select Transaction Type</MenuItem>
                                    {currentMember.length>0&&(handleFormattedAmount(amount) > 1200||currentMember[0].sharebalance>=1200||(memberShareCheck.length>0&&(parseInt(memberShareCheck[0].updatedShareBal)+parseFloat(handleFormattedAmount(amount)))>1200)) ?null :<MenuItem value={1} sx={{fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px",color:"#101010"}}>Share</MenuItem>}
                                    <MenuItem selected={handleFormattedAmount(amount) > 1200} value={2}>Subscription</MenuItem>
                                    {currentMember.length>0&&currentMember[0].loanbalance >0? <MenuItem value={5}>Loan Repayment</MenuItem>:null
                                    
                                    }
                                    {handleFormattedAmount(amount)==16&&currentMember.length>0&&checkEntry&&<MenuItem value={7}>Entrance Fee</MenuItem>}
                                    </Select>
                                    } 
                                    </>
                                        }
                                        </Box>
                                        <Box sx={{display:"flex",gap:"8px",flexDirection:"column"}}>
                                            <Typography sx={{ alignSelf: "stretch", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px",color:"#101010"}}>Loan No <Typography component="span" sx={{color:"red"}}>*</Typography> </Typography>
                                            <Dropdown
                                                value={loanid}
                                                disabled={transtype==5?false:true}
                                                onChange={(e)=>setLoanid(e.target.value)}
                                                placeholder={"Select Loan No"}
                                                options={loanids}
                                                style={{  width: "136px", height: "36px", display: "flex", alignItems: "center",background:"#FFFFFF"}}/>
                                  
                                        </Box>
                                    
                                    <button type="submit" ref={inputRefadd} variant="contained" disabled={memberid!==""&&handleFormattedAmount(amount)!==""&&transtype!==""&&Total+parseFloat(handleFormattedAmount(amount))<=handleFormattedAmount(bulkAmount)?transtype==1 &&parseInt(currentMember[0]?.sharebalance)+parseFloat(handleFormattedAmount(amount))>1200?true:transtype==5 && loanid == ""?true:false:true} style={{display:"flex",maxWidth:"159px",width:"100%",height:"36px",background:"#475569",border:"none",padding:"8px 12px",borderRadius:"4px",gap:"8px",justifyContent:"center",alignItems:"center",cursor: memberid!==""&&handleFormattedAmount(amount)!==""&&transtype!=="" &&Total+parseFloat(handleFormattedAmount(amount))<=handleFormattedAmount(bulkAmount)?transtype==1&&parseInt(currentMember[0]?.sharebalance)+parseFloat(handleFormattedAmount(amount))>1200?"not-allowed":transtype==5 && loanid == ""?"not-allowed":"pointer" : "not-allowed" ,border:"none",outline:"none"}} onClick={(e) => { handleAdd(e)}}>
                                             <Typography sx={{ alignSelf: "stretch", width: "100%", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px",color:"#FFFFFF",display:"flex",alignItems:"center",justifyContent:"center"}}>Add List</Typography>
                                    </button>
                                    {currentMember.length>0&&<Button onClick={(e)=>{
                                        clearform(e)
                                        }} variant="text"  sx={{height:"36px",borderRadius:"4px",padding:"12px",display:"flex",gap:"8px",background:"transparent", textTransform: "none"}}> <ClearIcon sx={{color:"#64748B"}}/> <Typography sx={{ alignSelf: "stretch",  fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px",color:"#000000",display:"flex",alignItems:"center"}}>Clear</Typography></Button>}
                                    {  payload && payload.bulkNo&&<Box sx={{display:"flex",justifyContent:"end"}}>
                          <IconButton sx={{display:"flex"}} onClick={handleMenuClick}> <MoreHorizIcon/></IconButton>
                          <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                            sx={{marginRight:"20px",padding:"0px"}}
                        >
                            {/* Menu options */}
                            <MenuItem disabled={payload && payload.bulkNo ?false:true} sx={{ '&:hover':{backgroundColor:"#288d2c",color:"#FFFFFF",transition:"0.3s ease-in-out "},width:"265px",height:"48px",padding:"12px 16px",fontFamily:"inter",fontSize:"16px",fontWeight:"400",lineHeight:"24px"}} onClick={(e)=>exportReceipt(e)}>Print Bulk Receipt</MenuItem>
                            <MenuItem disabled={payload && payload.bulkNo ?false:true} sx={{ '&:hover':{backgroundColor:"#288d2c",color:"#FFFFFF",transition:"0.3s ease-in-out "},width:"265px",height:"48px",padding:"12px 16px",fontFamily:"inter",fontSize:"16px",fontWeight:"400",lineHeight:"24px"}} onClick={(e)=>exportContributionList(e)}>Contribution List</MenuItem>
                            <MenuItem disabled={payload && payload.bulkNo ?false:true} sx={{ '&:hover':{backgroundColor:"#288d2c",color:"#FFFFFF",transition:"0.3s ease-in-out "},width:"265px",height:"48px",padding:"12px 16px",fontFamily:"inter",fontSize:"16px",fontWeight:"400",lineHeight:"24px"}} onClick={exportPdf}>Download List</MenuItem>
                            <MenuItem disabled={payload && payload.bulkNo ?false:true} sx={{ '&:hover':{backgroundColor:"#288d2c",color:"#FFFFFF",transition:"0.3s ease-in-out "},width:"265px",height:"48px",padding:"12px 16px",fontFamily:"inter",fontSize:"16px",fontWeight:"400",lineHeight:"24px"}} onClick={downlaomanagerLetter}>Manager Letter</MenuItem>
                        </Menu>
                     </Box>}
                     {errors.member !== "" &&
                                                    <Typography
                                                      sx={{
                                                        color: "red",
                                                        fontSize: "15px",
                                                      }}
                                                    >
                                                      {errors.member}
                                                </Typography>}
                                    </Box>
                                        
                                <Dialog visible={repeatedTransModal} style={{maxWidth:"553px",width:"100%",boxShadow:"0px 0px 34px 0px #64748B80"}} draggable={false} position="center" modal={false} closable={false} resizable={false}>
                                        <Box sx={{padding:"30px",display:"flex",flexDirection:"column",gap:"24px"}}>
                                                <Typography sx={{ alignSelf: "stretch",  fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px",color:"#000000"}}>This Record is already in the list</Typography>
                                        </Box>
                                        <Box sx={{display:"flex",justifyContent:"center",height:"96px",padding:"24px 30px",background:"#F1F5F9"}}>
                                            <Box sx={{display:"flex",gap:"24px"}}>
                                                <Button onClick={(e)=>{
                                                    e.preventDefault();
                                                    setRepeatedTransModal(false)}} variant="text"  sx={{maxWidth:"201px",width:"100%",height:"48px",borderRadius:"4px",padding:"12px",display:"flex",gap:"8px",background:"transparent", textTransform: "none"}}> <ClearIcon sx={{color:"#64748B"}}/><Typography sx={{color:"rgb(100, 116, 139)",fontSize:"16px",fontWeight:"400",lineHeight:"24px",fontFamily:"inter"}}>Cancel</Typography></Button>
                                            </Box>
                                         </Box>
                                </Dialog>
                                <Dialog visible={apiError} style={{maxWidth:"553px",width:"100%",boxShadow:"0px 0px 34px 0px #64748B80"}} draggable={false} position="center" modal={false} closable={false} resizable={false}>
                                        <Box sx={{padding:"30px",display:"flex",flexDirection:"column",gap:"24px"}}>
                                                <Typography sx={{ alignSelf: "stretch",  fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "20px",color:"#000000"}}>OOPS...Looks like transaction error !</Typography>
                                                <Typography sx={{ alignSelf: "stretch",  fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "20px",color:"#000000"}}>Please try to submit again</Typography>
                                        </Box>
                                        <Box sx={{display:"flex",justifyContent:"center",height:"96px",padding:"24px 30px",background:"#F1F5F9"}}>
                                            <Box sx={{display:"flex",gap:"24px"}}>
                                                <Button onClick={(e)=>{
                                                    e.preventDefault();
                                                    setApiError(false)}} variant="text"  sx={{maxWidth:"201px",width:"100%",height:"48px",borderRadius:"4px",padding:"12px",display:"flex",gap:"8px",background:"transparent", textTransform: "none"}}> <ClearIcon sx={{color:"#64748B"}}/><Typography sx={{color:"rgb(100, 116, 139)",fontSize:"16px",fontWeight:"400",lineHeight:"24px",fontFamily:"inter"}}>OK</Typography></Button>
                                            </Box>
                                         </Box>
                                </Dialog>
                                <Dialog visible={deleteModal} style={{maxWidth:"553px",width:"100%",boxShadow:"0px 0px 34px 0px #64748B80"}} draggable={false} position="center" modal={false} closable={false} resizable={false}>
                                        <Box sx={{padding:"30px",display:"flex",flexDirection:"column",gap:"24px"}}>
                                                <Typography sx={{ alignSelf: "stretch",  fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px",color:"#000000"}}>Do you want to delete this transaction </Typography>
                                               {action=="edit"&& <Typography sx={{ alignSelf: "stretch",  fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "600", lineHeight: "24px",color:"#000000"}}>RN : {rowDataToDelete?.transdetailid} </Typography>}
                                        </Box>
                                        <Box sx={{display:"flex",justifyContent:"center",height:"96px",padding:"24px 30px",background:"#F1F5F9"}}>
                                            <Box sx={{display:"flex",gap:"24px"}}>
                                                <Button onClick={(e)=>{
                                                    e.preventDefault();
                                                    setDeletemodal(false)}} variant="text"  sx={{maxWidth:"201px",width:"100%",height:"48px",borderRadius:"4px",padding:"12px",display:"flex",gap:"8px",background:"transparent", textTransform: "none"}}> <ClearIcon sx={{color:"#64748B"}}/><Typography sx={{color:"rgb(100, 116, 139)",fontSize:"16px",fontWeight:"400",lineHeight:"24px",fontFamily:"inter"}}>Cancel</Typography></Button>
                                                 <Button variant="contained" color="error" onClick={(e)=>{
                                                    e.preventDefault();
                                                    handleConfirmDelete()}}  sx={{maxWidth:"201px",width:"100%",height:"48px",borderRadius:"4px",padding:"12px",display:"flex",gap:"8px",background:"red", textTransform: "none",color:"#FFF"}}><Typography sx={{color:"#fff",fontSize:"16px",fontWeight:"400",lineHeight:"24px",fontFamily:"inter"}}> Delete</Typography></Button>
                                            </Box>
                                         </Box>
                                </Dialog>
                                {currentMember.length>0&&<Box>
                                <Box sx={{display:"flex",gap:"10px"}}>
                                        <Typography sx={{color:"#FFFFFF",fontSize:"18px",fontWeight:"600",lineHeight:"28px",fontFamily:"inter",color:"#64748B"}}>Transaction Summary</Typography>
                                </Box>
                                <Box sx={{display:"flex",gap:"16px",width:"100%",flexWrap:"wrap"}}>
                                    <Box sx={{width:"230px",height:"52px",display:"flex",flexDirection:"column",gap:"4px"}}>
                                        <Typography sx={{ alignSelf: "stretch", width: "100%", fontFamily: "inter", fontSize: "14px", fontStyle: "inter", fontWeight: "400", lineHeight: "20px",color:"#101010"}}>Share Balance: </Typography>
                                        <Typography sx={{ alignSelf: "stretch", width: "100%", fontFamily: "inter", fontSize: "18px", fontStyle: "inter", fontWeight: "700", lineHeight: "28px",color:"#288d2c"}}>RM: {currentMember.length>0?currentMember[0].sharebalance == null ? zerbalance : parseInt(currentMember[0].sharebalance).toFixed(2):0..toFixed(2)} </Typography>
                                    </Box>
                                    <Box sx={{width:"230px",height:"52px",display:"flex",flexDirection:"column",gap:"4px"}}>
                                        <Typography sx={{ alignSelf: "stretch", width: "100%", fontFamily: "inter", fontSize: "14px", fontStyle: "inter", fontWeight: "400", lineHeight: "20px",color:"#101010"}}>Subscription Balance: </Typography>
                                        <Typography sx={{ alignSelf: "stretch", width: "100%", fontFamily: "inter", fontSize: "18px", fontStyle: "inter", fontWeight: "700", lineHeight: "28px",color:"#288d2c"}}>RM: {currentMember.length>0?currentMember[0].subscriptionbalance == null ? zerbalance : parseInt(currentMember[0].subscriptionbalance).toFixed(2):0..toFixed(2)} </Typography>
                                    </Box>
                                    <Box sx={{width:"230px",height:"52px",display:"flex",flexDirection:"column",gap:"4px"}}>
                                        <Typography sx={{ alignSelf: "stretch", width: "100%", fontFamily: "inter", fontSize: "14px", fontStyle: "inter", fontWeight: "400", lineHeight: "20px",color:"#101010"}}>Loan Balance: </Typography>
                                        <Typography sx={{ alignSelf: "stretch", width: "100%", fontFamily: "inter", fontSize: "18px", fontStyle: "inter", fontWeight: "700", lineHeight: "28px",color:"#288d2c"}}>RM: {currentMember.length>0?currentMember[0].loanbalance == null ? zerbalance : parseInt(currentMember[0].loanbalance).toFixed(2):0..toFixed(2)} </Typography>
                                    </Box>
                                    <Box sx={{width:"230px",height:"52px",display:"flex",flexDirection:"column",gap:"4px"}}>
                                        <Typography sx={{ alignSelf: "stretch", width: "100%", fontFamily: "inter", fontSize: "14px", fontStyle: "inter", fontWeight: "400", lineHeight: "20px",color:"#101010"}}>Specific Deposit Balance: </Typography>
                                        <Typography sx={{ alignSelf: "stretch", width: "100%", fontFamily: "inter", fontSize: "18px", fontStyle: "inter", fontWeight: "700", lineHeight: "28px",color:"#288d2c"}}>RM: {currentMember.length>0?currentMember[0].sdbalance == null ? zerbalance : parseInt(currentMember[0].sdbalance).toFixed(2):0..toFixed(2)} </Typography>
                                    </Box>
                                    <Box sx={{width:"230px",height:"52px",display:"flex",flexDirection:"column",gap:"4px"}}>
                                        <Typography sx={{ alignSelf: "stretch", width: "100%", fontFamily: "inter", fontSize: "14px", fontStyle: "inter", fontWeight: "400", lineHeight: "20px",color:"#101010"}}>Borrowing Balance: </Typography>
                                        <Typography sx={{ alignSelf: "stretch", width: "100%", fontFamily: "inter", fontSize: "18px", fontStyle: "inter", fontWeight: "700", lineHeight: "28px",color:"#288d2c"}}>RM: {currentMember.length>0?currentMember[0].brbalance == null ? zerbalance : parseInt(currentMember[0].brbalance).toFixed(2):0..toFixed(2)} </Typography>
                                    </Box>
                                </Box>
                                <Box sx={{display:"flex",gap:"10px"}}>
                                        <Typography sx={{color:"#FFFFFF",fontSize:"18px",fontWeight:"600",lineHeight:"28px",fontFamily:"inter",color:"#64748B"}}>Active Loans</Typography>
                                </Box>
                                <Box className="select">
                                    <DataTable   value={loanList} rows={2}   style={{ backgroundColor: "#F1F5F9",height:loanList.length>0?"140px":"auto",fontSize:"12px"}} scrollable scrollHeight="140px"  columnfinalMembersizeMode="expand" emptyMessage="No Active Loans Found">
                                    {ColumnVisibleLoan.filter(col => col.visible).map((col,ind) => <Column style={{width:"204px",height:"40px"}} key={ind} field={col.field} filterElement={col.filterElement} header={col.header} sortable={col.sortable} body={col.body} filter={col.filter} alignFrozen="left" dataType={col.datatype} filterField={col.filterField} filterPlaceholder={col.filterPlaceholder} frozen={col.frozen} />)}
                                    </DataTable>
                                </Box>
                                </Box>}
                                </Box>
                                
                                </form>               
                            </Box>
                          
                                <Box className="datatable" sx={{width:"100%"}}>
                                    <DataTable value={finalMembers} rows={10} rowClassName={(rowData, index) => generateKey(rowData, index)} style={{ backgroundColor: "#F1F5F9",height:"600px"}} scrollable scrollHeight="600px"   removableSort  loading={isLoading}
                                        emptyMessage="No customers found." >
                                        <Column header="SN" headerStyle={{ minWidth: "50px",color:"#101010",fontFamily:"inter",fontSize:"14px",fontWeight:"500",lineHeight:"20px" }} frozen={true} body={(data, options) => options.rowIndex + 1}></Column>
                                        {columnVisible.filter(col => col.visible).map((col,ind) => <Column  style = {col.style} key={ind} filterElement={col.filterElement} field={col.field}  header={col.header} sortable={col.sortable} body={col.body} filter={col.filter} alignFrozen="left" dataType={col.datatype} filterField={col.filterField} filterPlaceholder={col.filterPlaceholder} frozen={col.frozen} editor={(rowData)=> finalMembers.length>0?inputEditor(rowData):null}/>)}
                                        <Column header ='Action' body={PrintData} style={{ minWidth: "160px",color:"#101010",fontFamily:"inter",fontSize:"14px",fontWeight:"500",lineHeight:"20px" }}/>
                                        {/* <Column header="Remove" body={deleteMem} style={{ minWidth: "160px",color:"#101010",fontFamily:"inter",fontSize:"14px",fontWeight:"500",lineHeight:"20px" }}/> */}
                                    </DataTable>
                                </Box>
                            </Box>
                           

                            <Box sx={{position:"absolute",top:"50%",left:"50%"}}>
                            
                            </Box>
                 </Box>
                 </Box>
                 <Box sx={{position:"sticky",bottom:0}}>
                    {/* <Box sx={{display:"flex",height:"64px",justifyContent:"space-between",padding:"16px 30px",background:"#E2E8F0"}}>
                        <Typography sx={{ alignSelf: "stretch", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px",color:"#64748B"}}>Balance <Typography component="span" sx={{ alignSelf: "stretch", fontFamily: "inter", fontSize: "20px", fontStyle: "inter", fontWeight: "700", lineHeight: "28px",color:"#64748B"}} >RM: {(bulkAmount - Total).toFixed(2)}</Typography></Typography>
                        <Typography sx={{ alignSelf: "stretch",  fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px",color:"#64748B"}}>Total Amount <Typography  component="span" sx={{ alignSelf: "stretch",  fontFamily: "inter", fontSize: "20px", fontStyle: "inter", fontWeight: "700", lineHeight: "28px",color:"#288d2c"}}>RM: {Total>0?Total.toFixed(2):0..toFixed(2)}</Typography></Typography>
                 </Box> */}
                 <Box sx={{height:"76px",width:"100%",display:"flex",justifyContent:"flex-end",gap:"24px",padding:"14px 30px",background:"#F1F5F9",pointerEvents:editdetails==true?"none":"auto"}}>
                 <Box sx={{display:"flex", flexDirection:"row", gap:"50px", marginRight:"300px", width:"325px"}}>
                 <Typography sx={{ alignSelf: "stretch", width: "100%", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "900", lineHeight: "24px",color:"rgb(100, 116, 139)"}}>Total : {parseInt(Total).toFixed(2)}</Typography>
                 <Typography sx={{ alignSelf: "stretch", width: "100%", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "900", lineHeight: "24px",color:"rgb(100, 116, 139)"}}>Balance : {parseInt(Bl).toFixed(2)}</Typography>
                 </Box>
                                 
                              
                </Box> 
                 </Box>
                 
            </Box>
            </form>

            
            }
        </Box>
    )
}
