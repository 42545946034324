import logo from './logo.svg';
import './App.css';

import React, { useEffect, useState } from 'react';

import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { ExecutiveSummery } from './pages/ExecutiveSummery';
import { MasterRecords } from './pages/master_records';
import { Dashboard } from './pages/dashboard';
import { DA_Dashboard } from "./pages/d&a_dashboard";
import { CreateProject } from "./pages/create-project";
import { Chat } from "./pages/chat-page";
import { Myprojects } from "./pages/myprojects";
import { Transactions } from './pages/transactions';
import { Login } from './pages/userAccount/login';
import { Signup } from './pages/userAccount/signup';
import { ForgotPassword } from './pages/userAccount/forgotPassword';
import { ResetPassword } from './pages/userAccount/resetPassword';
import Contact from './pages/contact';
import { Receipts } from './pages/userAccount/Receipts';
import { AccStatement } from './pages/userAccount/acc_statement';
import { Payments } from "./pages/userAccount/Payments"
import { Loan } from './pages/userAccount/loan_De';
import { SpecificDeposit } from './pages/specificDeposit';
import { Test } from './pages/test';
import { MemberLedger } from './pages/userAccount/member_ledger';

import { Box } from "@mui/material"
import { PrimeReactProvider } from 'primereact/api';
import { LinkHeader } from './components/linkHeader';
// import { ExecutiveSideBar } from "./components/ExecutiveSideBar";
import ExecutiveSideBar from './components/ExecutiveSideBar';
import { useDispatch, useSelector } from 'react-redux';
import { updateMemberListPayload } from './redux/executiveReducer';
import CustomAxios from './utils/CustomAxios';
import { Dividend } from './pages/Dividend';
import { Deduction } from './pages/Deduction';
import { Receiptscopy } from './pages/userAccount/Receipts copy';
import { PaymentsCopy } from "./pages/userAccount/PaymentsCopy"
import { EstateMaster } from './components/EstateMaster';
import { BankName } from './components/BankName';
import { CoOpBank } from './components/Co-opBank';
import { ShareCertificate } from './pages/shareCertificate';

import useDisableScroll from './hooks/useDisableScroll';

function App() {
  const api_base_url = process.env.REACT_APP_CL_URL
  const api = process.env.REACT_APP_OASIS_BE_URL
  const memberList = useSelector(state => state.executive.memberLists)
  //const api_base_url = "http://127.0.0.1:8000/"
  //const save_function='	http://127.0.0.1:8001/'

  const save_function = process.env.REACT_APP_IL_URL
  const [authToken, setAuthToken] = useState(localStorage.getItem('authToken'));
  const [refreshToken, setRefreshToken] = useState(localStorage.getItem('refreshToken'));
  const [role, setRole] = useState(localStorage.getItem('role'))
  const dispatch = useDispatch()
  const location = useLocation();
  const pathsWithNavBar = ["/login","/","/reset-password","/dashboard","/transactions","/contact_us"];

  // **********************USED FOR DISBALE SCROLL IN INPUT FIELD TYPE NUMBER*********************
  useDisableScroll();

  // Callback function to handle authentication in App.js
  const handleAuthLogin = (auth_data) => {
    // Store the token in local storage
    //console.log("auth token ----------------", auth_data)
    localStorage.setItem('authToken', auth_data.access_token);
    localStorage.setItem('refreshToken', auth_data.refresh_token);
    localStorage.setItem('username',auth_data.user_details.membername);
    localStorage.setItem('memberid',auth_data.user_details.memeberid);
    localStorage.setItem('role',auth_data.user_details.role);
 
    // Update the state to reflect authentication
    setAuthToken(auth_data.access_token);
    setRefreshToken(auth_data.refresh_token)
    setRole(auth_data.user_details.role)
  };
  useEffect(()=>{
const debouncedSearch = async() => {
        try {
          const res = await CustomAxios.get(`oasis/memberList`)
          console.log(res)
          if(memberList.length==0){
            dispatch(updateMemberListPayload(res.data.memberList))
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }
    if(authToken && refreshToken && (role === 'admin' || role === 'staff'))
       debouncedSearch()

  },[authToken, refreshToken, role])

  return (
    <>
      {(pathsWithNavBar.includes(location.pathname) || (pathsWithNavBar.includes(location.pathname.slice(0, location.pathname.length - 1)) && location.pathname[location.pathname.length - 1] === "/")) ?
          (<Routes>
            <Route path="/login" element={<Login handleAuthLogin={handleAuthLogin} />}></Route>
            <Route path="/reset-password" element={<ResetPassword />}></Route>
            <Route path="/dashboard" element={authToken && refreshToken ? <Dashboard /> : <Navigate to="/login" />} ></Route>
            <Route path="/transactions" element={authToken && refreshToken ? <Transactions /> : <Navigate to="/login" />} ></Route>
            <Route path="/contact_us" element={authToken && refreshToken ? <Contact /> : <Navigate to="/login" />} ></Route>
          </Routes>)
          :(<PrimeReactProvider>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            {/* <LinkHeader /> */}
            <Box sx={{ display: "flex", }}>
              <ExecutiveSideBar />
              <Routes>
                      <Route path="/testing" element={<Test />}></Route>
                      <Route path="/Receipts_new" element={authToken && refreshToken ? <Receiptscopy /> : <Navigate to="/login" />}></Route>
                      <Route eact path="/myprojects" element={<Myprojects api_base_url={api_base_url} />}></Route>
                      {/* <Route path="/forgot-password" element={<ForgotPassword />}></Route> */}
                      <Route path="/analysis" element={<DA_Dashboard api_base_url={api_base_url} savedata={save_function} />} />
                      <Route path="/new-project" element={<CreateProject api_base_url={api_base_url} savedata={save_function} />} />
                      <Route path="/new-project/:projectname" element={<CreateProject api_base_url={api_base_url} savedata={save_function} />} />
                      <Route path="chat" element={<Chat api_base_url={api_base_url} savedata={save_function} />} />
                      <Route path="/executive_summary" element={authToken && refreshToken ? <ExecutiveSummery /> : <Navigate to="/login" />} ></Route>
                      {/* <Route path="/master_records" element={authToken && refreshToken ? <MasterRecords /> : <Navigate to="/login" />} ></Route> */}
                      <Route path="/estate" element={authToken && refreshToken ? <EstateMaster /> : <Navigate to="/login" />} ></Route>
                      <Route path="/bankname" element={authToken && refreshToken ? <BankName /> : <Navigate to="/login" />} ></Route>
                      <Route path="/coopbank" element={authToken && refreshToken ? <CoOpBank /> : <Navigate to="/login" />} ></Route>
                      <Route path="/payments" element={authToken && refreshToken ? <Payments /> : <Navigate to="/login" />}></Route>
                      <Route path="/loan" element={authToken && refreshToken ? <Loan /> : <Navigate to="/login" />}></Route>
                      {/* <Route path="/account-statement" element={authToken && refreshToken ? <AccStatement /> : <Navigate to="/login" />}></Route> */}
                      {/* <Route path="/member_ledger" element={authToken && refreshToken ? <MemberLedger /> : <Navigate to="/login" />}></Route> */}
                      <Route path="/specificDeposit" element={authToken && refreshToken ? <SpecificDeposit /> : <Navigate to="/login" />} />
                      {/* <Route path="/loan_lists" element={authToken && refreshToken ? <LoanList /> : <Navigate to="/login" />}></Route> */}
                      <Route path="/add_member" element={authToken && refreshToken ? <Signup />: <Navigate to="/login" />}></Route>
                      <Route path="/dividend" element={authToken && refreshToken ? <Dividend />: <Navigate to="/login" />}></Route>
                      <Route path="/deduction" element={authToken && refreshToken ? <Deduction />: <Navigate to="/login" />}></Route>
                      <Route path="/Receipts" element={authToken && refreshToken ? <Receipts /> : <Navigate to="/login" />}></Route>
                      <Route path="/payments_new" element={authToken && refreshToken ? <PaymentsCopy /> : <Navigate to="/login" />}></Route>
                      <Route path="/share_certificate" element={authToken && refreshToken ? <ShareCertificate /> : <Navigate to="/login" />}></Route>
                      <Route path="/account-statement" element={authToken && refreshToken ? <ExecutiveSummery /> : <Navigate to="/login" />}></Route>
             </Routes>
        </Box>
          </Box>
        </PrimeReactProvider>)
      }

    </>
  );
}

export default App;